export default function useCookie (name: string): string | null {
    const nameLenPlus = name.length + 1;
    return (
        document.cookie
            .split(';')
            .map((c) => {
                return c.trim();
            })
            .filter((cookie) => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map((cookie) => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null
    );
}

export const eraseCookie = (name: string): void => {
    document.cookie = `${name}=; Max-Age=-99999999;`;
};
