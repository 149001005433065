import checkInRoutes from '@/router/check-in';
import guestRoutes from '@/router/guest';
import myProfileRoutes from '@/router/myProfile';
import {
    KDS_CONNECT_KEY_LOCAL_STORAGE,
    KDS_CONNECT_KEY_PARAMETER,
    MODULES
} from '@/utils/constants';
import { hubspot } from '@/utils/hubspot';
import {
    createRouter,
    createWebHistory,
    RouteLocationNormalized,
    RouteRecordRaw
} from 'vue-router';
import NProgress from 'nprogress';
import { Component } from 'vue';
import can from '@/utils/can';
import dashboardRoutes from '@/router/dashboard';
import {
    fetchUser,
    hasModuleForCurrentOrg,
    userState
} from '@/composables/useUser';
import haloAdminRoutes from '@/router/haloAdmin';
import adminRoutes from '@/router/admin';
import givingRoutes from '@/router/giving';
import authRoutes from '@/router/auth';
import launchRoutes from '@/router/launch';
import useContext, { Context } from '@/composables/useContext';
import { getDefaultRoute, onPage } from '@/composables/useHistory';
import { AppName, BaseUrl } from '@/composables/useConfig';
import { getExtensionFromString, getUrlParam } from '@/utils/helper';

declare module 'vue-router' {
    interface RouteMeta {
        isHaloAdmin?: boolean;
        isAdmin?: boolean;
        isCentralApp?: boolean;
        auth?: boolean;
        can?: string | string[];
        requiredModule?: string;
        skipLaunchProjectCheck?: boolean;
    }
}

const routes: Array<RouteRecordRaw> = [
    ...authRoutes,
    ...guestRoutes,
    {
        path: '/',
        redirect: { name: getDefaultRoute() },
        component: () => {
            return import('../layouts/TenantLayout.vue');
        },
        props: true,
        meta: { auth: true },
        children: [
            ...givingRoutes,
            ...launchRoutes,
            ...checkInRoutes,
            ...myProfileRoutes,
            {
                path: 'accounts',
                name: 'accounts',
                components: {
                    default: () => {
                        return import('../views/profile/ProfileAccounts.vue');
                    },
                    billingNotice: (): Component => {
                        return import('../components/alerts/BillingNotice.vue');
                    }
                },
                meta: { auth: false }
            }
        ]
    },
    {
        path: '/system-admin',
        component: () => {
            return import('../layouts/AdminLayout.vue');
        },
        meta: {
            auth: true,
            isHaloAdmin: true,
            isCentralApp: true
        },
        children: [...haloAdminRoutes]
    },
    {
        path: '/admin',
        redirect: { name: 'organizations.index' },
        component: () => {
            return import('../layouts/TenantLayout.vue');
        },
        meta: {
            auth: true,
            isAdmin: true
        },
        children: [...adminRoutes]
    },
    {
        path: '/dashboard',
        component: () => {
            return import('../layouts/TenantLayout.vue');
        },
        meta: { auth: true },
        children: [...dashboardRoutes]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        redirect: { name: getDefaultRoute() }
    }
];

const updateDocumentTitle = (to: RouteLocationNormalized) => {
    document.title = `${to.meta.title ? `${to.meta.title} | ` : ''}${
        AppName as string
    }`;
};

const updateDocumentIcon = (to: RouteLocationNormalized) => {
    const faviconElements = document.querySelectorAll('link[rel="icon"]');
    faviconElements.forEach((el) => {
        el.setAttribute(
            'href',
            `${BaseUrl}${
                to.meta.icon ? (to.meta.icon as string) : 'favicon'
            }${getExtensionFromString(el.getAttribute('href') ?? '')}`
        );
    });
};

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});

router.beforeResolve((to, from, next) => {
    if (to.path) {
        NProgress.start();
        onPage(from, to);
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

router.beforeEach(async (to, from, next) => {
    updateDocumentTitle(to);

    updateDocumentIcon(to);

    if (['production'].includes(import.meta.env.VITE_ENV)) {
        hubspot.trackPageView(to.path);
    }

    // Redirect to KDS Migration page if there is a connection key in the GET parameters and if the user is already logged in.
    const cloudKey = getUrlParam(KDS_CONNECT_KEY_PARAMETER);
    if (cloudKey) {
        localStorage.setItem(KDS_CONNECT_KEY_LOCAL_STORAGE, cloudKey);

        if (userState.check()) {
            return next({ name: 'giving.kdsMigration.index' });
        }
    }

    if (to.meta.guest) {
        if (userState.check()) {
            return next({ name: getDefaultRoute() });
        }
    } else if (!userState.check()) {
        await fetchUser();
    }

    if (to.meta.auth) {
        if (!userState.check()) {
            return next({ name: 'login' });
        }

        if (!to.meta.skipBillingCheck && !(await userState.checkBilling())) {
            return next({ name: 'accounts' });
        }
    }

    if (to.meta.isHaloAdmin && !userState.isHaloAdmin()) {
        return next({ name: 'not-found' });
    }

    if (to.meta.isAdmin && !userState.isAdmin()) {
        return next({ name: 'not-found' });
    }

    if (to.meta.isCentralApp) {
        useContext().setContext(Context.centralApp);
    } else {
        useContext().setContext(Context.tenantApp);
    }

    if (
        (to.meta.can && !can(to.meta.can)) ||
        (to.meta.requiredModule &&
            !hasModuleForCurrentOrg(to.meta.requiredModule))
    ) {
        return next({ name: 'not-found' });
    }

    // Launch Module project check
    if (
        to.meta.requiredModule === MODULES.LAUNCH &&
        !to.meta.skipLaunchProjectCheck &&
        !userState.getUser.value.launchProject?.launchAdminTemplate?.prefixedId
    ) {
        // Let's give one more try before redirecting the user
        await fetchUser();

        if (
            !userState.getUser.value.launchProject?.launchAdminTemplate
                ?.prefixedId
        ) {
            return next({ name: 'launch.selectPlan' });
        }
    }

    if (
        to.meta.requiredModule === MODULES.LAUNCH &&
        to.meta.skipLaunchProjectCheck &&
        userState.getUser.value.launchProject?.launchAdminTemplate?.prefixedId
    ) {
        await fetchUser();

        if (
            userState.getUser.value.launchProject?.launchAdminTemplate
                ?.prefixedId
        ) {
            return window.location.replace(`/launch/dashboard`);
        }
    }

    return next();
});

// Vue Lazy Routes & loading chunk failed
// https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
router.onError((error) => {
    if ((/loading chunk \d* failed./i).test(error.message)) {
        window.location.reload();
    }
});

export default router;
