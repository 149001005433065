<template>
    <svg
        viewBox="0 0 584.9 126.1"
        class="h-full"
        fill="#ffffff"
    >
        <path d="M152.7,86.7H144V22.5h8.7V86.7z"/>
        <path
            d="M190.3,75.6h-0.9c-2.3,7.7-8.3,12.1-16.6,12.1c-8.8,0-14.5-4-14.5-13c0-8.1,5.2-14,16.6-14h15v-3.8c0-7-2.7
            -10.8-10.3-10.8c-6.3,0-9.8,3.1-11.3,9.3l-8.2-2c1.9-9,8.6-14.5,19.8-14.5c13,0,18.7,6.3,18.7,17.9v29.9h-8.3V75
            .6zM189.9,69.3v-2.6h-13.7c-6.1,0-9.6,2.4-9.6,7c0,3.7,2.3,7,9.2,7C184.4,80.7,189.9,75.9,189.9,69.3z"
        />
        <path
            d="M238.6,74.5h-0.8c-2.6,8.3-8.8,13.4-16.9,13.4c-10.5,0-15.2-6.9-15.2-17.1V40h8.7v28.7
				c0,6.5,2.7,11.2,10.2,11.2c8.3,0,13.8-5.2,13.8-13.2V40h8.7v46.7h-8.5V74.5z"
        />
        <path
            d="M263.7,86.7H255V40h8.5v12.1h0.9c2.6-8.3,8.8-13.3,16.9-13.3c10.4,0,15.1,6.8,15.1,17v30.9h-8.7V57.9
				c0-6.4-2.6-11.2-10.1-11.2c-8.3,0-13.9,5.3-13.9,13.3V86.7z"
        />
        <path
            d="M324.2,46.5c-8.8,0-14.2,5.7-14.2,16.9c0,10.3,5.1,16.8,14.6,16.8c8.6,0,11.4-5.2,13.1-11.5l8,2.1
				c-2.1,10.7-8.5,17.1-21,17.1c-14.7,0-23.6-8.9-23.6-24.4c0-15.5,9.3-24.7,23.3-24.7c13.1,0,19.2,6.2,21.1,17.2l-8.1,1.7
				C335.8,50.8,332.6,46.5,324.2,46.5z"
        />
        <path
            d="M359.6,86.7h-8.7V22.5h8.7v29.6h0.7c2.7-8.2,8.6-13.3,17.1-13.3c10.2,0,14.9,6.8,14.9,17v30.9h-8.7V57.9
				c0-6.4-2.8-11.2-10-11.2c-8.5,0-14,5.3-14,13.3V86.7z"
        />
        <path
            d="M423.6,86.7h-8.7V22.5h8.7v29.6h0.7c2.7-8.2,8.6-13.3,17.1-13.3c10.2,0,14.9,6.8,14.9,17v30.9h-8.7V57.9
				c0-6.4-2.8-11.2-10-11.2c-8.5,0-14,5.3-14,13.3V86.7z"
        />
        <path
            d="M493.1,75.6h-0.9c-2.3,7.7-8.3,12.1-16.6,12.1c-8.8,0-14.5-4-14.5-13c0-8.1,5.2-14,16.6-14h15v-3.8c0-7-2.7
            -10.8-10.3-10.8c-6.3,0-9.8,3.1-11.3,9.3l-8.2-2c1.9-9,8.6-14.5,19.8-14.5c13,0,18.7,6.3,18.7,17.9v29.9h-8.3V75
            .6zM492.7,69.3v-2.6H479c-6.1,0-9.6,2.4-9.6,7c0,3.7,2.3,7,9.2,7C487.2,80.7,492.7,75.9,492.7,69.3z"
        />
        <path d="M518.1,86.7h-8.7V22.5h8.7V86.7z"/>
        <path
            d="M523.6,63c0-15.3,9.2-24.4,23.4-24.4c14.1,0,23.4,9.1,23.4,24.4c0,15.2-8.6,24.7-23.4,24.7
				C532.2,87.7,523.6,78.2,523.6,63z M532.3,62.9c0,10.5,4.7,17.2,14.6,17.2c9.9,0,14.8-6.7,14.8-17.2s-5.1-16.8-14.7-16.8
				S532.3,52.4,532.3,62.9z"
        />
        <path
            d="M89.4,69.6c-13.2-11.6-30.2-19-48.9-20c0,0-0.1,0,0-0.1c5.5-9.9,10.9-19.9,16.3-29.8c1.2-2.1,4.2-2.1,5.3,0
		c6,10.9,12,21.8,18,32.7L89.4,69.6C89.4,69.5,89.4,69.6,89.4,69.6z"
        />
        <path d="M99.1,90H18.2c6.3-11.5,12.6-23,19-34.6C63.1,55.9,86,69.5,99.1,90z"/>
    </svg>

</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'LaunchLogoWhite' });
</script>
