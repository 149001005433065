/* eslint no-underscore-dangle: 0 */

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Organization, Tenant, User } from '@/graphql/operations';
import { SignUpData } from '@/types';

@Module({ name: 'account', namespaced: true })
export default class Account extends VuexModule {
    localStorageKeys = {
        activeOrganization: 'activeOrganization',
        viewingTenant: 'viewingTenant',
        viewingOrganization: 'viewingOrganization',
        impersonatedUser: 'impersonatedUser',
        impersonationReturnUrl: 'impersonationReturnUrl',
        isKdsMigrationCompleted: 'isKdsMigrationCompleted'
    };

    _activeOrganization: Organization | null = null;

    _viewingTenant: Tenant | null = null;

    _viewingOrganization: Organization   | null = null;

    _impersonatedUser: User | null = null;

    _impersonationReturnUrl = '';

    _searchQuery: string | undefined;

    _recoveredSearchQuery: string | undefined;

    _isKdsMigrationCompleted: boolean | undefined;

    _signUpData: SignUpData = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirmation: ''
    };

    _givingDashboardFilters = {
        startDate: '',
        endDate: ''
    };

    /* *************************************************
                       SEARCH QUERY
    ************************************************* */
    get searchQuery (): string | undefined {
        return this._searchQuery;
    }

    get recoveredSearchQuery (): string | undefined {
        return this._recoveredSearchQuery;
    }

    @Mutation
    setSearchQuery (searchQuery: string | undefined): void {
        this._searchQuery = searchQuery;
    }

    @Action({ commit: 'setSearchQuery' })
    doSetSearchQuery (searchQuery: string | undefined): string | undefined {
        return searchQuery;
    }

    @Mutation
    setRecoveredSearchQuery (recoveredSearchQuery: string | undefined): void {
        this._recoveredSearchQuery = recoveredSearchQuery;
    }

    @Action({ commit: 'setRecoveredSearchQuery' })
    doSetRecoveredSearchQuery (recoveredSearchQuery: string | undefined): string | undefined {
        return recoveredSearchQuery;
    }

    /* *************************************************
                       SIGN UP DATA
    ************************************************* */
    get signUpData (): SignUpData {
        return this._signUpData;
    }

    get hasValidSignUpData (): boolean {
        return !!(
            this._signUpData.firstName &&
            this._signUpData.lastName &&
            this._signUpData.email &&
            this._signUpData.password &&
            this._signUpData.passwordConfirmation
        );
    }

    @Mutation
    setSignUpData (signUpData: SignUpData): void {
        this._signUpData = signUpData;
    }

    @Action({ commit: 'setSignUpData' })
    doSetSignUpData (signUpData: SignUpData): SignUpData {
        return signUpData;
    }

    /* *************************************************
                     ACTIVE ORGANIZATION
    ************************************************* */
    get activeOrganization (): Organization | null {
        if (!this._activeOrganization) {
            const localStorageValue = localStorage.getItem(
                this.localStorageKeys.activeOrganization
            );
            if (localStorageValue) {
                try {
                    return JSON.parse(localStorageValue);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._activeOrganization;
    }

    @Mutation
    setActiveOrganization (activeOrganization: Organization): void {
        this._activeOrganization = activeOrganization;
    }

    @Action({ commit: 'setActiveOrganization' })
    doSetActiveOrganization (
        activeOrganization: Organization | null
    ): Organization | null {
        if (activeOrganization) {
            localStorage.setItem(
                this.localStorageKeys.activeOrganization,
                JSON.stringify(activeOrganization)
            );
        } else {
            localStorage.removeItem(this.localStorageKeys.activeOrganization);
        }

        return activeOrganization;
    }

    /* *************************************************
                      VIEWING TENANT
    ************************************************* */
    get viewingTenant (): Tenant | null {
        if (!this._viewingTenant) {
            const localStorageValue = localStorage.getItem(
                this.localStorageKeys.viewingTenant
            );
            if (localStorageValue) {
                try {
                    return JSON.parse(localStorageValue);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._viewingTenant;
    }

    @Mutation
    resetViewingTenant (): void {
        this._viewingTenant = null;
        localStorage.removeItem(this.localStorageKeys.viewingTenant);
    }

    @Mutation
    setViewingTenant (viewingTenant: Tenant): void {
        this._viewingTenant = viewingTenant;
    }

    @Action({ commit: 'setViewingTenant' })
    doSetViewingTenant (
        viewingTenant: Tenant | null
    ): Tenant | null {
        if (viewingTenant) {
            localStorage.setItem(
                this.localStorageKeys.viewingTenant,
                JSON.stringify(viewingTenant)
            );
        } else {
            localStorage.removeItem(this.localStorageKeys.viewingTenant);
        }

        return viewingTenant;
    }

    /* *************************************************
                     VIEWING ORGANIZATION
    ************************************************* */
    get viewingOrganization (): Organization | null {
        if (!this._viewingOrganization) {
            const localStorageValue = localStorage.getItem(
                this.localStorageKeys.viewingOrganization
            );
            if (localStorageValue) {
                try {
                    return JSON.parse(localStorageValue);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._viewingOrganization;
    }

    @Mutation
    resetViewingOrganization (): void {
        this._viewingOrganization = null;
        localStorage.removeItem(this.localStorageKeys.viewingOrganization);
    }

    @Mutation
    setViewingOrganization (viewingOrganization: Organization): void {
        this._viewingOrganization = viewingOrganization;
    }

    @Action({ commit: 'setViewingOrganization' })
    doSetViewingOrganization (
        viewingOrganization: Organization | null
    ): Organization | null {
        if (viewingOrganization) {
            localStorage.setItem(
                this.localStorageKeys.viewingOrganization,
                JSON.stringify(viewingOrganization)
            );
        } else {
            localStorage.removeItem(this.localStorageKeys.viewingOrganization);
        }

        return viewingOrganization;
    }

    /* *************************************************
                       IMPERSONATION
    ************************************************* */
    get impersonatedUser (): User | null {
        if (!this._impersonatedUser) {
            const localStorageValue = localStorage.getItem(
                this.localStorageKeys.impersonatedUser
            );
            if (localStorageValue) {
                try {
                    return JSON.parse(localStorageValue);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._impersonatedUser;
    }

    get impersonationReturnUrl (): string {
        if (!this._impersonationReturnUrl) {
            const localStorageValue = localStorage.getItem(this.localStorageKeys.impersonationReturnUrl);
            if (localStorageValue) {
                try {
                    return localStorageValue;
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._impersonationReturnUrl;
    }

    @Mutation
    resetImpersonatedUser (): void {
        this._impersonatedUser = null;
        localStorage.removeItem(this.localStorageKeys.impersonatedUser);
    }

    @Mutation
    resetImpersonationReturnUrl (): void {
        this._impersonationReturnUrl = '';
        localStorage.removeItem(this.localStorageKeys.impersonationReturnUrl);
    }

    @Mutation
    setImpersonated ([impersonatedUser, impersonationReturnUrl]: [ User, string ]): void {
        this._impersonatedUser = impersonatedUser;
        this._impersonationReturnUrl = impersonationReturnUrl;
    }

    @Action({ commit: 'setImpersonated' })
    doSetImpersonated ([impersonatedUser, impersonationReturnUrl]: [ User, string ]): [ User, string ] {
        localStorage.setItem(this.localStorageKeys.impersonatedUser, JSON.stringify(impersonatedUser));
        localStorage.setItem(this.localStorageKeys.impersonationReturnUrl, impersonationReturnUrl);

        return [impersonatedUser, impersonationReturnUrl];
    }

    get isKdsMigrationCompleted (): boolean | undefined {
        return this._isKdsMigrationCompleted;
    }

    @Mutation
    setIsKdsMigrationCompleted (isKdsMigrationCompleted: boolean): void {
        this._isKdsMigrationCompleted = isKdsMigrationCompleted;
    }

    @Action({ commit: 'setIsKdsMigrationCompleted' })
    doSetIsKdsMigrationCompleted (isKdsMigrationCompleted: boolean): boolean {
        return isKdsMigrationCompleted;
    }

    /* *************************************************
                       GIVING DASHBOARD FILTERS
    ************************************************* */
    get givingDashboardFilters (): { startDate: string; endDate: string } {
        return this._givingDashboardFilters;
    }

    @Mutation
    setGivingDashboardFilters (filters: { startDate: string; endDate: string }): void {
        this._givingDashboardFilters = filters;
    }

    @Action({ commit: 'setGivingDashboardFilters' })
    doSetGivingDashboardFilters (filters: { startDate: string; endDate: string }): { startDate: string; endDate: string } {
        return filters;
    }
}
