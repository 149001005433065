import { Component } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import i18n from '../i18n';

const { t } = i18n.global;

const haloAdminRoutes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: 'system-admin.dashboard',
        redirect: { name: 'tenants.index' }
    },
    {
        path: '',
        component: (): Component => {
            return import('../views/tenant/Tenants.vue');
        },
        meta: { title: t('navigation.systemAdmin') },
        children: [
            {
                path: '',
                name: 'tenants.index',
                props: true,
                component: (): Component => {
                    return import('../views/tenant/TenantIndex.vue');
                }
            },
            {
                path: ':tenantPrefixedId',
                component: (): Component => {
                    return import('../views/tenant/Tenant.vue');
                },
                props: true,
                children: [
                    {
                        path: 'profile-info',
                        component: (): Component => {
                            return import('../layouts/TenantProfile.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'tenants.organizations.index',
                                component: (): Component => {
                                    return import('../views/organizations/OrganizationIndex.vue');
                                },
                                props: true
                            },
                            {
                                path: 'create',
                                name: 'tenants.organizations.create',
                                component: (): Component => {
                                    return import('../views/organizations/OrganizationCreate.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'organizations',
                        redirect: { name: 'tenants.organizations.profile.index' },
                        component: (): Component => {
                            return import('../layouts/SystemAdminOrganizationProfile.vue');
                        },
                        props: true,
                        children: [
                            {
                                path: ':organizationPrefixedId',
                                name: 'tenants.organizations.profile.index',
                                component: (): Component => {
                                    return import('../views/organizations/organizationProfile/OrganizationProfileView.vue');
                                },
                                props: true
                            },
                            {
                                path: ':organizationPrefixedId/edit',
                                name: 'tenants.organizations.profile.edit',
                                component: (): Component => {
                                    return import('../views/organizations/organizationProfile/OrganizationEdit.vue');
                                },
                                props: true
                            },
                            {
                                path: ':organizationPrefixedId/billing',
                                name: 'tenants.organizations.profile.billing',
                                component: (): Component => {
                                    return import('../views/billing/BillingIndex.vue');
                                },
                                meta: { skipBillingCheck: true },
                                props: true
                            },
                            {
                                path: ':organizationPrefixedId/activity',
                                name: 'tenants.organizations.profile.activity',
                                component: (): Component => {
                                    return import('../views/tenant/ActivityLog.vue');
                                },
                                props: (route) => {
                                    return {
                                        organizationPrefixedId: route.params.organizationPrefixedId,
                                        tenantPrefixedId: route.params.tenantPrefixedId,
                                        type: 'organization'
                                    };
                                }
                            }
                        ]
                    },
                    {
                        path: 'users',
                        name: 'tenants.users.index',
                        component: (): Component => {
                            return import('../views/users/UserIndex.vue');
                        },
                        props: (route) => {
                            return { tenantPrefixedId: route.params.tenantPrefixedId };
                        }
                    },
                    {
                        path: 'system-admin-profile',
                        name: 'system-admin.profile',
                        redirect: { name: 'system-admin.profile.index' },
                        component: (): Component => {
                            return import('../views/Profile.vue');
                        },
                        meta: { auth: true },
                        props: true,
                        children: [
                            {
                                path: ':personPrefixedId?',
                                name: 'system-admin.profile.index',
                                component: (): Component => {
                                    return import('../views/profile/ProfileView.vue');
                                },
                                props: true
                            },
                            {
                                path: ':personPrefixedId?/edit',
                                name: 'system-admin.profile.edit',
                                component: (): Component => {
                                    return import('../views/profile/ProfileEdit.vue');
                                },
                                props: true
                            },
                            {
                                path: ':personPrefixedId/update-contact',
                                name: 'system-admin.profile.updateContact',
                                component: (): Component => {
                                    return import('../views/profile/ContactUpdate.vue');
                                },
                                props: true
                            },
                            {
                                path: 'add',
                                name: 'system-admin.profile.add',
                                component: (): Component => {
                                    return import('../views/profile/UserAdd.vue');
                                },
                                props: true
                            },
                            {
                                path: ':personPrefixedId?/contact',
                                name: 'system-admin.profile.contact',
                                component: (): Component => {
                                    return import('../views/profile/ContactView.vue');
                                },
                                props: true
                            },
                            {
                                path: ':personPrefixedId?/giving',
                                name: 'system-admin.profile.giving',
                                component: (): Component => {
                                    return import('../views/profile/ProfileGiving.vue');
                                },
                                props: true
                            },
                            {
                                path: ':personPrefixedId?/admin',
                                name: 'system-admin.profile.permissions',
                                component: (): Component => {
                                    return import('../views/profile/ProfilePermissions.vue');
                                },
                                props: true
                            },
                            {
                                path: ':personPrefixedId?/activities',
                                name: 'system-admin.profile.activities',
                                component: (): Component => { return import('../views/tenant/ActivityLog.vue'); },
                                props: (route) => {
                                    return {
                                        personPrefixedId: route.params.personPrefixedId,
                                        tenantPrefixedId: route.params.tenantPrefixedId,
                                        type: 'person'
                                    };
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: 'roles',
        component: (): Component => {
            return import('../views/roles/Roles.vue');
        },
        meta: { title: t('navigation.systemAdmin') },
        children: [
            {
                path: '',
                name: 'roles.index',
                component: (): Component => {
                    return import('../views/roles/RolesIndex.vue');
                }
            },
            {
                path: 'create',
                name: 'roles.create',
                component: (): Component => {
                    return import('../views/roles/RolesCreate.vue');
                }
            },
            {
                path: ':role/edit',
                name: 'roles.edit',
                component: (): Component => {
                    return import('../views/roles/RolesEdit.vue');
                },
                props: true
            }
        ]
    },
    {
        path: 'affiliate-codes',
        component: (): Component => {
            return import('../views/affiliateCodes/AffiliateCodes.vue');
        },
        children: [
            {
                path: '',
                name: 'affiliate-codes.index',
                component: (): Component => {
                    return import('../views/affiliateCodes/AffiliateCodesIndex.vue');
                }
            }
        ]
    },
    {
        path: 'launch-templates',
        redirect: { name: 'launch-templates.index' },
        meta: { title: t('navigation.systemAdmin') },
        component: (): Component => {
            return import('../views/launchTemplates/LaunchTemplates.vue');
        },
        children: [
            {
                path: '',
                name: 'launch-templates.index',
                component: (): Component => {
                    return import('../views/launchTemplates/LaunchTemplatesIndex.vue');
                }
            },
            {
                path: '',
                component: (): Component => {
                    return import('../views/launchTemplates/launchTemplate/LaunchTemplate.vue');
                },
                props: true,
                children: [
                    {
                        path: ':templateName/:templatePrefixedId',
                        name: 'launch-template.index',
                        component: (): Component => {
                            return import('../views/launchTemplates/launchTemplate/LaunchTemplateIndex.vue');
                        },
                        props: true
                    },
                    {
                        path: ':templateName/:templatePrefixedId/template-edit',
                        name: 'launch-template.edit',
                        component: (): Component => {
                            return import('../views/launchTemplates/launchTemplate/LaunchTemplateEdit.vue');
                        },
                        props: true
                    },
                    {
                        path: 'create',
                        name: 'launch-template.create',
                        component: (): Component => {
                            return import('../views/launchTemplates/launchTemplate/LaunchTemplateCreate.vue');
                        }
                    },
                    {
                        path: '',
                        redirect: { name: 'launch-phases.index' },
                        component: (): Component => {
                            return import('../views/launchTemplates/launchTemplate/launchPhases/LaunchPhases.vue');
                        },
                        children: [
                            {
                                path: ':templateName/:templatePrefixedId/phases',
                                name: 'launch-phases.index',
                                component: (): Component => {
                                    return import('../views/launchTemplates/launchTemplate/launchPhases/LaunchPhasesIndex.vue');
                                },
                                props: true
                            },
                            {
                                path: ':templateName/:templatePrefixedId/phases',
                                redirect: { name: 'launch-phases.index' },
                                component: (): Component => {
                                    return import('../views/launchTemplates/launchTemplate/launchPhases/launchPhase/LaunchPhase.vue');
                                },
                                children: [
                                    {
                                        path: ':phaseTitle/:phasePrefixedId',
                                        name: 'launch-phase.index',
                                        component: (): Component => {
                                            return import('../views/launchTemplates/launchTemplate/launchPhases/launchPhase/LaunchPhaseIndex.vue');
                                        },
                                        props: true
                                    },
                                    {
                                        path: ':phaseTitle/:phasePrefixedId/edit',
                                        name: 'launch-phase.edit',
                                        component: (): Component => {
                                            return import('../views/launchTemplates/launchTemplate/launchPhases/launchPhase/LaunchPhaseEdit.vue');
                                        },
                                        props: true
                                    },
                                    {
                                        path: 'create',
                                        name: 'launch-phase.create',
                                        component: (): Component => {
                                            return import('../views/launchTemplates/launchTemplate/launchPhases/launchPhase/LaunchPhaseCreate.vue');
                                        },
                                        props: true
                                    },
                                    {
                                        path: ':phaseTitle/:phasePrefixedId/topic',
                                        component: (): Component => {
                                            return import('../views/launchTemplates/launchTemplate/launchPhases/launchPhase/LaunchPhase.vue');
                                        },
                                        children: [
                                            {
                                                path: ':topicPrefixedId/edit',
                                                name: 'launch-topic.edit',
                                                component: (): Component => {
                                                    return import('../views/launchTemplates/launchTemplate/launchPhases/launchPhase/launchTopic/LaunchTopicEdit.vue');
                                                },
                                                props: true
                                            },
                                            {
                                                path: 'create',
                                                name: 'launch-topic.create',
                                                component: (): Component => {
                                                    return import('../views/launchTemplates/launchTemplate/launchPhases/launchPhase/launchTopic/LaunchTopicCreate.vue');
                                                },
                                                props: true
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export default haloAdminRoutes;
