import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
// Modules
import Attendance from '@/store/modules/attendance';
import Contribution from '@/store/modules/contribution';
import Flash from '@/store/modules/flash';
import Donor from '@/store/modules/donor';
import User from '@/store/modules/user';
import Account from '@/store/modules/account';
import Launch from '@/store/modules/launch';
import Profile from '@/store/modules/profile';
import Tour from '@/store/modules/tour';

export let contributionStore: Contribution;
export let donorStore: Donor;
export let flashStore: Flash;
export let userStore: User;
export let accountStore: Account;
export let attendanceStore: Attendance;
export let launchStore: Launch;
export let profileStore: Profile;
export let tourStore: Tour;

export function initialiseStores (store: Store<unknown>): void {
    contributionStore = getModule(Contribution, store);
    donorStore = getModule(Donor, store);
    flashStore = getModule(Flash, store);
    userStore = getModule(User, store);
    accountStore = getModule(Account, store);
    attendanceStore = getModule(Attendance, store);
    launchStore = getModule(Launch, store);
    profileStore = getModule(Profile, store);
    tourStore = getModule(Tour, store);
}

export const modules = {
    contribution: Contribution,
    donor: Donor,
    flash: Flash,
    user: User,
    account: Account,
    attendance: Attendance,
    launch: Launch,
    profile: Profile,
    tour: Tour
};
