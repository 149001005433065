import { useI18n } from 'vue-i18n';
import * as Yup from 'yup';

const setYupLocale = (i18n: any) => {
    Yup.setLocale({
        mixed: {
            default: () => {
                return i18n.t('validation.default');
            },
            required: () => {
                return i18n.t('forms.validation.required');
            }
        },
        string: {
            email: () => {
                return i18n.t('forms.validation.email');
            },
            max: (max) => {
                return i18n.t('forms.validation.max', { max: max.max });
            },
            min: (min) => {
                return i18n.t('forms.validation.min', { min: min.min });
            }
        }
        // ... other validation types and translations
    });
};

export const configureYupLocale = () => {
    const i18n = useI18n();
    setYupLocale(i18n);
};
