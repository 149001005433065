<template>
    <svg
        viewBox="0 0 338.62 300.83"
        fill="#ffffff"
    >
        <path
            d="M297.8,215.15c-55.3-48.54-126.43-79.37-204.59-83.64-.13,0-.21-.15-.15-.27,22.82-41.54,45.61-83.12,68.42-124.66,4.82-8.77,17.42-8.77,22.24,0,25.05,45.66,50.13,91.35,75.16,136.99l39.19,71.36c.1,.18-.12,.35-.27,.22Z"
        />
        <path
            d="M338.62,300.83H0c26.43-48.17,52.9-96.45,79.33-144.62,108.78,1.61,204.32,58.89,259.29,144.62Z"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'LaunchIconWhite' });
</script>
