import i18n from '@/i18n';

const trans = {
    get defaultLocale () {
        return 'en';
    },

    get supportedLocales () {
        return ['en', 'es'];
    },

    set currentLocale (newLocale: 'en' | 'es') {
        i18n.global.locale.value = newLocale;
    },

    isLocaleSupported (locale: string) {
        return trans.supportedLocales?.includes(locale);
    },

    guessDefaultLocale (): string {
        const userPersistedLocale = trans.getPersistedLocale();

        if (userPersistedLocale) {
            return userPersistedLocale;
        }

        localStorage.setItem('user-locale', trans.defaultLocale);
        return trans.defaultLocale;
    },

    async switchLanguage (newLocale: 'en' | 'es') {
        trans.currentLocale = newLocale;
        document.querySelector('html')?.setAttribute('lang', newLocale);
        localStorage.setItem('user-locale', newLocale);
    },

    getPersistedLocale () {
        const persistedLocale = localStorage.getItem('user-locale');

        if (!persistedLocale) {
            return null;
        }

        if (trans.isLocaleSupported(persistedLocale)) {
            return persistedLocale;
        }

        return null;
    }
};
export default trans;
