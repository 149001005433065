/* eslint no-underscore-dangle: 0 */

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ name: 'contribution', namespaced: true })
export default class Contribution extends VuexModule {
    _selectedContributions: string[] = [];

    _deletedContributions: string[] = [];

    get selectedContributions (): string[] {
        return this._selectedContributions;
    }

    @Mutation
    reset (): void {
        this._selectedContributions = [];
        this._deletedContributions = [];
    }

    @Mutation
    selectContributions (selectedContributions: string[]): void {
        this._selectedContributions = selectedContributions;
    }

    @Action({ commit: 'selectContributions' })
    doSelectContributions (selectedContributions: string[]): string[] {
        return selectedContributions;
    }

    @Mutation
    deleteContributions (deletedContributions: string[]): void {
        this._deletedContributions = deletedContributions;
    }

    @Action({ commit: 'deleteContributions' })
    doDeleteContributions (deletedContributions: string[]): string[] {
        return deletedContributions;
    }
}
