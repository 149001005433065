// Needs translation
import { MODULES } from '@/utils/constants';
import { Component } from 'vue';
import { RouteRecordRaw } from 'vue-router';

const checkInRoutes: Array<RouteRecordRaw> = [
    {
        path: 'check-in',
        name: 'check-in',
        redirect: { name: 'check-in.dashboard' },
        components: {
            default: (): Component => {
                return import('../views/check-in/CheckIn.vue');
            },
            navigationLinks: (): Component => {
                return import('../components/navigation/CheckInLinks.vue');
            },
            billingNotice: (): Component => {
                return import('../components/alerts/BillingNotice.vue');
            }
        },
        meta: {
            can: 'test:feature-check-in',
            requiredModule: MODULES.CHECK_IN,
            title: 'Check-In Halo'
            // icon: `halo_launch_favicon`
        },
        children: [
            {
                path: 'dashboard',
                name: 'check-in.dashboard',
                component: (): Component => {
                    return import('../views/check-in/dashboard/CheckInDashboard.vue');
                }
            },
            {
                path: 'people',
                component: (): Component => {
                    return import('../views/check-in/people/People.vue');
                },
                children: [
                    {
                        path: '',
                        name: 'check-in.people.index',
                        component: (): Component => {
                            return import('../views/check-in/people/PeopleIndex.vue');
                        }
                    }
                ]
            },
            {
                path: 'check-in-profile',
                name: 'check-in.profile',
                redirect: { name: 'check-in.profile.contact' },
                component: (): Component => {
                    return import('../views/Profile.vue');
                },
                meta: { auth: true },
                props: true,
                children: [
                    {
                        path: ':personPrefixedId?',
                        name: 'check-in.profile.index',
                        component: (): Component => {
                            return import('../views/profile/ProfileView.vue');
                        },
                        props: true
                    },
                    {
                        path: ':userPrefixedId?/edit',
                        name: 'check-in.profile.edit',
                        component: (): Component => {
                            return import('../views/profile/ProfileEdit.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/admin',
                        name: 'check-in.profile.permissions',
                        component: (): Component => {
                            return import('../views/profile/ProfilePermissions.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/update-contact',
                        name: 'check-in.profile.updateContact',
                        component: (): Component => {
                            return import('../views/profile/ContactUpdate.vue');
                        },
                        props: true
                    },
                    {
                        path: 'add',
                        name: 'check-in.profile.add',
                        component: (): Component => {
                            return import('../views/profile/ContactAdd.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/launch',
                        name: 'check-in.profile.launch',
                        component: (): Component => {
                            return import('../views/profile/ProfileLaunch.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/contact',
                        name: 'check-in.profile.contact',
                        component: (): Component => {
                            return import('../views/profile/ContactView.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/giving',
                        name: 'check-in.profile.giving',
                        component: (): Component => {
                            return import('../views/profile/ProfileGiving.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/families',
                        name: 'check-in.profile.families',
                        component: (): Component => {
                            return import('../views/profile/ProfileFamilies.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/notifications',
                        name: 'check-in.profile.notifications',
                        component: (): Component => {
                            return import('../views/profile/ProfileNotifications.vue');
                        },
                        props: true
                    }

                ]
            },
            {
                path: 'classes',
                component: (): Component => {
                    return import('../views/check-in/classes/ClassLayout.vue');
                },
                children: [
                    {
                        path: '',
                        component: (): Component => {
                            return import('../views/check-in/classes/Class.vue');
                        },
                        children: [
                            {
                                path: '',
                                name: 'check-in.class.index',
                                component: (): Component => {
                                    return import('../views/check-in/classes/ClassIndex.vue');
                                },
                                props: true
                            },
                            {
                                path: 'create',
                                name: 'check-in.class.create',
                                component: (): Component => {
                                    return import('../views/check-in/classes/ClassCreate.vue');
                                },
                                props: false
                            },
                            {
                                path: ':groupPrefixedId/edit',
                                name: 'check-in.class.edit',
                                component: (): Component => {
                                    return import('../views/check-in/classes/ClassEdit.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'volunteers',
                        name: 'check-in.class.volunteers',
                        component: (): Component => {
                            return import('../views/check-in/classes/ClassVolunteers.vue');
                        }
                    }
                ]
            },
            {
                path: 'settings',
                component: (): Component => {
                    return import('../views/check-in/settings/SettingsLayout.vue');
                },
                children: [
                    {
                        path: '',
                        name: 'check-in.settings',
                        redirect: { name: 'check-in.settings.eventTemplates.index' },
                        component: (): Component => {
                            return import('../views/check-in/settings/EventTemplate.vue');
                        },
                        children: [
                            {
                                path: 'event-templates',
                                name: 'check-in.settings.eventTemplates.index',
                                component: (): Component => {
                                    return import('../views/check-in/settings/EventTemplateIndex.vue');
                                },
                                props: true
                            },
                            {
                                path: 'event-templates/create',
                                name: 'check-in.settings.eventTemplates.create',
                                component: (): Component => {
                                    return import('../views/check-in/settings/EventTemplateCreate.vue');
                                },
                                props: false
                            },
                            {
                                path: ':eventTemplatePrefixedId/edit',
                                name: 'check-in.settings.eventTemplates.edit',
                                component: (): Component => {
                                    return import('../views/check-in/settings/EventTemplateEdit.vue');
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'volunteers',
                        name: 'check-in.class.volunteers',
                        component: (): Component => {
                            return import('../views/check-in/classes/ClassVolunteers.vue');
                        }
                    }
                ]
            }
        ]
    }
];

export default checkInRoutes;
