<template>
    <svg
        viewBox="0 0 443.05 299.48"
        fill="#ffffff"
    >
        <polygon class="d" points="182.52 219.73 136.71 297.36 0 170.46 128.62 169.69 182.52 219.73"/>
        <path
            class="d"
            d="M277.34,280.89c-6.88,11.63-18.61,18.59-31.37,18.59h-80.81l36.54-61.93L341.88,0h101.17l-165.71,280.89Z"
        />
        <path
            class="d"
            d="M443.05,0l-165.71,280.89c-6.88,11.63-18.61,18.59-31.37,18.59h-80.81l36.54-61.93L341.88,0h101.17Z"
        />
    </svg>

</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'CheckInIconWhite' });
</script>
