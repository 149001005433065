<template>
    <svg
        viewBox="0 -29 608.45 126.3"
        class="h-full"
        fill="#ffffff"
    >
        <polygon points="36.94 45.78 27.67 61.49 0 35.81 26.03 35.65 36.94 45.78"/>
        <path
            d="M56.14,58.16c-1.39,2.35-3.77,3.76-6.35,3.76h-16.36l7.4-12.54L69.2,1.31h20.48L56.14,58.16Z"
        />
        <path
            d="M89.68,1.31L56.14,58.16c-1.39,2.35-3.77,3.76-6.35,3.76h-16.36l7.4-12.54L69.2,1.31h20.48Z"
        />
        <path
            d="M139.44,23.38c-8.57,0-13.83,5.55-13.83,16.47,0,10.04,4.97,16.37,14.22,16.37,8.38,0,11.11-5.07,12.76-11.2l7.79,2.05c-2.05,10.42-8.28,16.66-20.46,16.66-14.32,0-22.99-8.67-22.99-23.77s9.06-24.06,22.7-24.06c12.76,0,18.71,6.04,20.56,16.76l-7.89,1.66c-1.56-6.72-4.68-10.91-12.86-10.91Z"
        />
        <path
            d="M173.93,62.55h-8.48V0h8.48V28.84h.68c2.63-7.99,8.38-12.96,16.66-12.96,9.94,0,14.52,6.62,14.52,16.56v30.11h-8.48v-28.06c0-6.24-2.73-10.91-9.74-10.91-8.28,0-13.64,5.16-13.64,12.96v26.01Z"
        />
        <path
            d="M210.27,40.14c0-15.2,8.87-24.26,22.6-24.26s20.46,8.38,20.46,20.95c0,1.36-.1,3.41-.29,5.46h-34.29c.68,9.26,5.55,14.32,14.32,14.32,8.18,0,11.01-4.09,12.47-9.16l7.4,2.05c-2.24,8.67-8.09,14.13-19.88,14.13-14.13,0-22.8-8.38-22.8-23.48Zm8.57-3.99h26.6c-.39-8.67-4.38-13.54-12.76-13.54s-12.86,4.48-13.84,13.54Z"
        />
        <path
            d="M277.98,23.38c-8.57,0-13.83,5.55-13.83,16.47,0,10.04,4.97,16.37,14.22,16.37,8.38,0,11.11-5.07,12.76-11.2l7.79,2.05c-2.05,10.42-8.28,16.66-20.46,16.66-14.32,0-22.99-8.67-22.99-23.77s9.06-24.06,22.7-24.06c12.76,0,18.71,6.04,20.56,16.76l-7.89,1.66c-1.56-6.72-4.68-10.91-12.86-10.91Z"
        />
        <path
            d="M312.96,36.44h4.48l16.47-19.39h9.65v1.07l-19.39,21.34,21.04,22.02v1.07h-9.74l-17.83-19.68h-4.68v19.68h-8.48V0h8.48V36.44Z"
        />
        <path d="M372.87,34.88v8.09h-30.01v-8.09h30.01Z"/>
        <path d="M376.97,8.28V.58h9.74v7.7h-9.74Zm.58,54.27V17.05h8.48V62.55h-8.48Z"/>
        <path
            d="M403.17,62.55h-8.48V17.05h8.28v11.79h.88c2.53-8.09,8.57-12.96,16.47-12.96,10.13,0,14.71,6.62,14.71,16.56v30.11h-8.48v-28.06c0-6.24-2.53-10.91-9.84-10.91-8.09,0-13.54,5.16-13.54,12.96v26.01Z"
        />
        <path
            d="M465.53,62.55h-8.48V0h8.48V28.84h.68c2.63-7.99,8.38-12.96,16.66-12.96,9.94,0,14.52,6.62,14.52,16.56v30.11h-8.48v-28.06c0-6.24-2.73-10.91-9.74-10.91-8.28,0-13.64,5.16-13.64,12.96v26.01Z"
        />
        <path
            d="M533.14,51.73h-.88c-2.24,7.5-8.09,11.79-16.17,11.79-8.57,0-14.13-3.9-14.13-12.67,0-7.89,5.07-13.64,16.17-13.64h14.61v-3.7c0-6.82-2.63-10.52-10.04-10.52-6.14,0-9.55,3.02-11.01,9.06l-7.99-1.95c1.85-8.77,8.38-14.13,19.29-14.13,12.67,0,18.22,6.14,18.22,17.44v29.13h-8.09v-10.81Zm-.39-6.14v-2.53h-13.35c-5.94,0-9.35,2.34-9.35,6.82,0,3.6,2.24,6.82,8.96,6.82,8.38,0,13.74-4.68,13.74-11.11Z"
        />
        <path d="M557.5,62.55h-8.48V0h8.48V62.55Z"/>
        <path
            d="M562.85,39.65c0-14.91,8.96-23.77,22.8-23.77s22.8,8.87,22.8,23.77-8.38,24.06-22.8,24.06-22.8-9.26-22.8-24.06Zm8.48-.1c0,10.23,4.58,16.76,14.22,16.76s14.42-6.53,14.42-16.76-4.97-16.37-14.32-16.37-14.32,6.14-14.32,16.37Z"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'CheckInLogoWhite' });
</script>
