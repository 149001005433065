/* eslint no-underscore-dangle: 0 */

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ name: 'attendance', namespaced: true })
export default class Attendance extends VuexModule {
    _selectedAttendances: string[] = [];

    _deletedAttendances: string[] = [];

    get selectedAttendances (): string[] {
        return this._selectedAttendances;
    }

    @Mutation
    reset (): void {
        this._selectedAttendances = [];
        this._deletedAttendances = [];
    }

    @Mutation
    selectAttendances (selectedAttendances: string[]): void {
        this._selectedAttendances = selectedAttendances;
    }

    @Action({ commit: 'selectAttendances' })
    doSelectAttendances (selectedAttendances: string[]): string[] {
        return selectedAttendances;
    }

    @Mutation
    deleteAttendances (deletedAttendances: string[]): void {
        this._deletedAttendances = deletedAttendances;
    }

    @Action({ commit: 'deleteAttendances' })
    doDeleteAttendances (deletedAttendances: string[]): string[] {
        return deletedAttendances;
    }
}
