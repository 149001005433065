import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import NProgress from 'nprogress';
import Hotjar from 'vue-hotjar';
import { createGtm } from '@gtm-support/vue-gtm';
import { client } from '@/graphql/client';
import i18n from './i18n';
import App from './App.vue';
import '@/assets/styles.css';
import router from './router';
import can from './utils/can';
import store from './store';
import { isLocalStorageAvailable } from '@/utils/helper';
// import { datadogRum } from '@datadog/browser-rum';

const app = createApp(App);

app.config.globalProperties.$can = can;

NProgress.configure({ showSpinner: false });

// if (!['testing', 'local'].includes(import.meta.env.VITE_ENV)) {
//     datadogRum.init({
//         applicationId: '9be60d35-e701-4e9e-81e5-649405b6b578',
//         clientToken: 'pube8475fc95d7d1af8f4c0e7324f1e8849',
//         site: 'datadoghq.com',
//         service:'church-halo',
//         env:'prod',
//         // Specify a version number to identify the deployed version of your application in Datadog
//         // version: '1.0.0',
//         sessionSampleRate:100,
//         sessionReplaySampleRate: 20,
//         trackUserInteractions: true,
//         trackResources: true,
//         trackLongTasks: true,
//         defaultPrivacyLevel:'mask-user-input',
//         trackFrustrations: true
//     });
//
//     datadogRum.startSessionReplayRecording();
// }

Sentry.init({
    app,
    environment: import.meta.env.VITE_ENV,
    dsn: import.meta.env.VITE_SENTRY_URL,
    release: `halo@${import.meta.env.npm_package_version}`,
    integrations: [
        Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        })
    ],
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/* eslint-disable */
app.config.errorHandler = (err, vm, info) => {
    Sentry.captureException(err);

    if (["development", "local"].includes(import.meta.env.VITE_ENV)) {
        console.error({ err, vm, info });
    }
};

window.addEventListener("error", (event) => {
    Sentry.captureException(event);

    if (["development", "local"].includes(import.meta.env.VITE_ENV)) {
        console.error(event);
    }
});

window.addEventListener("unhandledrejection", (event) => {
    Sentry.captureException(event);

    if (["development", "local"].includes(import.meta.env.VITE_ENV)) {
        console.error(event);
    }
});

if (import.meta.env.VITE_HOTJAR_SITE_ID && isLocalStorageAvailable()) {
    app.use(Hotjar, { id: import.meta.env.VITE_HOTJAR_SITE_ID });
}

if (import.meta.env.VITE_GTM_ID) {
    app.use(
        createGtm({
            id: import.meta.env.VITE_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
            // queryParams: {
            //     // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
            //     gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
            //     gtm_preview: 'env-4',
            //     gtm_cookies_win: 'x'
            // },
            defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
            compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
            // nonce: '2726c7f26c', // Will add `nonce` to the script tag
            enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
            debug: import.meta.env.VITE_ENV !== "production", // Whether display console logs debugs (optional)
            loadScript: true, // Whether to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
            vueRouter: router, // Pass the router instance to automatically sync with router (optional)
            // ignoredViews: [ 'homepage' ], // Don't trigger events for specified router names (optional)
            trackOnNextTick: false, // Whether call trackView in Vue.nextTick
        })
    );
}

app.use(client).use(i18n).use(router).use(store).mount("#app");
