import { CheckLaunchTasksDocument, Exact, UncheckLaunchTasksDocument } from '@/graphql/operations';
import i18n from '@/i18n';
import { LaunchPhase, LaunchTask, LaunchTopic, LaunchAdminTemplate } from '@/types';
import { useMutation } from 'villus';

const shouldTranslate = () => {
    return i18n.global.locale.value === 'es';
};

const translateTopic = (topic: LaunchTopic): LaunchTopic => {
    if (!topic) {
        return topic;
    }

    const translateItem = (item: LaunchTask | LaunchTopic) => {
        return {
            ...item,
            _localizedTitle: shouldTranslate() ? (item.titleEs || item.title) : item.title
        };
    };

    const translateTasks = (tasks: LaunchTask[]): LaunchTask[] => {
        if (!tasks) {
            return tasks;
        }
        return tasks.map(translateItem) as LaunchTask[];
    };

    return {
        ...topic,
        nextTopic: topic.nextTopic ? translateTopic(topic.nextTopic) : undefined,
        previousTopic: topic.previousTopic ? translateTopic(topic.previousTopic) : undefined,
        _localizedTitle: shouldTranslate() ? (topic.titleEs || topic.title) : topic.title,
        launchTasks: translateTasks(topic.launchTasks)
    };
};
const translatePhase = (phase: LaunchPhase): LaunchPhase => {
    if (!phase) {
        return phase;
    }

    return {
        ...phase,
        _localizedTitle: shouldTranslate() ? (phase.titleEs || phase.title) : phase.title,
        _localizedDescription: shouldTranslate() ? (phase.descriptionEs || phase.description) : phase.description,
        launchTopics: phase.launchTopics.map((topic) => {
            return translateTopic(topic);
        })
    };
};

const translateTemplate = (template: LaunchAdminTemplate) => {
    if (!template) {
        return template;
    }

    return {
        ...template,
        _localizedName: shouldTranslate() ? (template.nameEs || template.name) : template.name,
        _localizedSubheader: shouldTranslate() ? (template.subheaderEs || template.subheader) : template.subheader,
        _localizedDescription: shouldTranslate() ? (template.descriptionEs || template.description) : template.description
    };
};

const graphqlService = {
    checkLaunchTasks: async (variables: Exact<{
        prefixedIds: string | string[];
        projectPrefixedId: string;
    }> | undefined) => {
        const { execute } = useMutation(CheckLaunchTasksDocument);
        return await execute(variables);
    },

    uncheckLaunchTasks: async (variables: Exact<{
        prefixedIds: string | string[];
        projectPrefixedId: string;
    }> | undefined) => {
        const { execute } = useMutation(UncheckLaunchTasksDocument);
        return await execute(variables);
    }
};

export {
    graphqlService,
    translateTopic,
    translatePhase,
    translateTemplate
};
