import { MODULES } from '@/utils/constants';
import { Component } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import i18n from '../i18n';

const { t } = i18n.global;

const launchRoutes: Array<RouteRecordRaw> = [
    {
        path: 'launch',
        name: 'launch',
        redirect: { name: 'launch.dashboard' },
        components: {
            default: (): Component => {
                return import('../views/launch/Launch.vue');
            },
            navigationLinks: (): Component => {
                return import('../components/navigation/LaunchLinks.vue');
            },
            billingNotice: (): Component => {
                return import('../components/alerts/BillingNotice.vue');
            }
        },
        meta: {
            can: 'view:launch-module',
            requiredModule: MODULES.LAUNCH,
            title: t('modules.launch'),
            icon: `halo_launch_favicon`
        },
        children: [
            {
                path: 'dashboard',
                name: 'launch.dashboard',
                component: (): Component => {
                    return import('../views/launch/dashboard/LaunchDashboard.vue');
                }
            },
            {
                path: 'welcome',
                name: 'launch.welcome',
                props: true,
                component: (): Component => {
                    return import('../views/launch/welcome/LaunchWelcome.vue');
                },
                meta: { skipLaunchProjectCheck: true }
            },
            {
                path: 'pick-your-plan',
                name: 'launch.selectPlan',
                component: (): Component => {
                    return import('../views/launch/welcome/LaunchSelectPlan.vue');
                },
                meta: { skipLaunchProjectCheck: true }
            },
            {
                path: 'plan',
                name: 'launch.plan',
                component: (): Component => {
                    return import('../views/launch/plan/LaunchPlan.vue');
                }
            },
            {
                path: 'people',
                component: (): Component => {
                    return import('../views/People.vue');
                },
                props: { module: MODULES.LAUNCH },
                children: [
                    {
                        path: '',
                        name: 'launch.people.index',
                        component: (): Component => {
                            return import('../views/launch/people/PeopleIndex.vue');
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'launch-profile',
                name: 'launch.profile',
                redirect: { name: 'launch.profile.contact' },
                component: (): Component => {
                    return import('../views/Profile.vue');
                },
                meta: { auth: true },
                props: true,
                children: [
                    {
                        path: ':personPrefixedId?/admin',
                        name: 'launch.profile.permissions',
                        component: (): Component => {
                            return import('../views/profile/ProfilePermissions.vue');
                        },
                        props: true
                    },
                    {
                        path: ':userPrefixedId?',
                        name: 'launch.profile.index',
                        component: (): Component => {
                            return import('../views/profile/ProfileView.vue');
                        },
                        props: true
                    },
                    {
                        path: ':userPrefixedId?/edit',
                        name: 'launch.profile.edit',
                        component: (): Component => {
                            return import('../views/profile/ProfileEdit.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/update-contact',
                        name: 'launch.profile.updateContact',
                        component: (): Component => {
                            return import('../views/profile/ContactUpdate.vue');
                        },
                        props: true
                    },
                    {
                        path: 'add',
                        name: 'launch.profile.add',
                        component: (): Component => {
                            return import('../views/profile/ContactAdd.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/launch',
                        name: 'launch.profile.launch',
                        component: (): Component => {
                            return import('../views/profile/ProfileLaunch.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/contact',
                        name: 'launch.profile.contact',
                        component: (): Component => {
                            return import('../views/profile/ContactView.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/giving',
                        name: 'launch.profile.giving',
                        component: (): Component => {
                            return import('../views/profile/ProfileGiving.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/families',
                        name: 'launch.profile.families',
                        component: (): Component => {
                            return import('../views/profile/ProfileFamilies.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/notifications',
                        name: 'launch.profile.notifications',
                        component: (): Component => {
                            return import('../views/profile/ProfileNotifications.vue');
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'settings',
                name: 'launch.settings',
                redirect: { name: 'launch.settings.churchDetails' },
                component: (): Component => {
                    return import('../views/launch/settings/LaunchSettings.vue');
                },
                children: [
                    {
                        path: 'church-details',
                        name: 'launch.settings.churchDetails',
                        component: (): Component => {
                            return import('../views/launch/settings/ChurchDetails.vue');
                        }
                    },
                    {
                        path: 'church-details/edit',
                        name: 'launch.settings.churchDetailsEdit',
                        component: (): Component => {
                            return import('../views/launch/settings/ChurchDetailsEdit.vue');
                        }
                    },
                    {
                        path: 'plan-settings',
                        name: 'launch.settings.planSettings',
                        component: (): Component => {
                            return import('../views/launch/settings/PlanSettings.vue');
                        }
                    },
                    {
                        path: 'fund-settings',
                        name: 'launch.settings.fundSettings',
                        component: (): Component => {
                            return import('../views/launch/settings/FundSettings.vue');
                        },
                        meta: {
                            can: 'view:giving-module',
                            requiredModule: MODULES.GIVING
                        }
                    },
                    {
                        path: 'calendar',
                        name: 'launch.settings.calendar',
                        component: (): Component => {
                            return import('../views/launch/settings/CalendarTool.vue');
                        }
                    }
                ]
            },
            {
                path: 'tasks',
                redirect: { name: 'launch.tasks.index' },
                component: (): Component => {
                    return import('../views/launch/tasks/TasksLayout.vue');
                },
                children: [
                    {
                        path: '',
                        name: 'launch.tasks.index',
                        component: (): Component => {
                            return import('../views/launch/tasks/TasksIndex.vue');
                        }
                    },
                    {
                        path: '',
                        component: (): Component => {
                            return import('../views/launch/tasks/details/TasksDetailsLayout.vue');
                        },
                        children: [
                            {
                                path: ':topicPrefixedId/details',
                                name: 'launch.tasks.details',
                                component: (): Component => {
                                    return import('../views/launch/tasks/details/TasksDetails.vue');
                                },
                                props: true
                            },
                            {
                                path: ':topicPrefixedId/content',
                                name: 'launch.tasks.content',
                                component: (): Component => {
                                    return import('../views/launch/tasks/details/TopicContent.vue');
                                },
                                props: true
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export default launchRoutes;
