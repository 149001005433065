<template>
    <svg
        viewBox="0 0 286.87 300.83"
        fill="#ffffff"
    >
        <path d="M286.87,21.66V205.46h-57.23V105.32L13.24,0h251.97c11.96,0,21.66,9.7,21.66,21.66Z"/>
        <polygon points="209.11 118.16 209.11 300.83 13.19 205.46 0 199.05 0 16.42 209.11 118.16"/>
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'GivingIconWhite' });
</script>
