const getApiUrl = (): string => {
    if (window.Cypress) {
        return Cypress.env('VITE_API_URL');
    } else {
        return import.meta.env.VITE_API_URL;
    }
};

const getApiBaseUrl = (): string => {
    if (window.Cypress) {
        return Cypress.env('VITE_API_BASE_URL');
    } else {
        return import.meta.env.VITE_API_BASE_URL;
    }
};

export { getApiBaseUrl, getApiUrl };
