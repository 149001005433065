import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ name: 'profile', namespaced: true })
export default class Profile extends VuexModule {
    _name: string | null = null;

    get name (): string | null {
        return this._name;
    }

    @Mutation
    reset (): void {
        this._name = null;
    }

    @Mutation
    setName (name: string): void {
        this._name = name;
    }

    @Action({ commit: 'setName' })
    doSetName (name: string): string {
        return name;
    }
}
