import { userState } from '@/composables/useUser';

export default function can (needle: string | string[]): boolean {
    const user = userState.getUser;

    if (user.value.abilities === undefined) {
        return false;
    }

    if (Array.isArray(needle)) {
        return needle.every((ability) => { return user.value.abilities?.includes(ability); });
    }

    return user.value.abilities.includes(needle);
}
