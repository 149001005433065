<template>
    <svg
        viewBox="0 0 289.67 289.67"
        fill="#ffffff"
    >
        <path
            d="M188.33,62.46c5.17,2.74,10.05,5.95,14.59,9.57,6.59,5.26,12.45,11.41,17.4,18.25,6.16,8.51,10.92,18.11,13.93,28.45,1.63,5.6,2.75,11.42,3.31,17.4h17.35c-.46-5.95-1.4-11.76-2.76-17.4-2.93-12.11-7.86-23.43-14.42-33.61-4.93-7.66-10.78-14.66-17.4-20.86-5.33-5-11.16-9.47-17.4-13.34-4.64-2.88-9.52-5.42-14.59-7.6v19.15Z"
        />
        <path
            d="M74.52,118.73c9.63-25.94,33.2-45.1,61.61-48.38v-18.25c-38.51,3.57-70.22,30.58-80.72,66.63H19.8C31.28,63.48,78.46,21.31,136.13,17.43V0C68.83,3.98,13.87,53.83,2.08,118.73c-1.03,5.69-1.74,11.5-2.08,17.4H70.35c.7-6.05,2.12-11.88,4.17-17.4"
        />
        <path
            d="M202.92,136.13h16.39c-.7-6.05-2.12-11.88-4.17-17.4-2.9-7.8-7.05-14.99-12.22-21.31-8.36-10.23-19.38-18.22-31.99-22.9V19.8c11.28,2.34,22.02,6.17,31.99,11.28,6.1,3.12,11.92,6.72,17.4,10.74,6.26,4.59,12.08,9.75,17.4,15.38,6.64,7.03,12.48,14.82,17.4,23.22,6.85,11.71,11.89,24.61,14.74,38.31,1.18,5.67,1.98,11.48,2.38,17.4h17.43c-.35-5.9-1.05-11.71-2.08-17.4-2.77-15.25-7.92-29.67-15.06-42.85-4.91-9.07-10.75-17.55-17.4-25.32-5.31-6.2-11.13-11.95-17.4-17.18-5.47-4.57-11.29-8.74-17.4-12.47-5.56-3.4-11.38-6.43-17.4-9.06-10.11-4.42-20.83-7.73-31.99-9.76-5.69-1.03-11.5-1.74-17.4-2.08V87.35c6.17,.93,12.02,2.82,17.4,5.53,11.15,5.61,20.24,14.71,25.85,25.85,2.71,5.38,4.6,11.23,5.53,17.4h.61Z"
        />
        <path
            d="M101.33,227.2c-5.17-2.74-10.05-5.95-14.59-9.57-6.59-5.26-12.45-11.41-17.4-18.25-6.16-8.51-10.92-18.11-13.93-28.45-1.63-5.6-2.75-11.42-3.31-17.4h-17.35c.46,5.94,1.4,11.76,2.76,17.4,2.93,12.11,7.86,23.43,14.42,33.61,4.93,7.66,10.78,14.66,17.4,20.86,5.33,5,11.16,9.47,17.4,13.34,4.65,2.88,9.52,5.42,14.59,7.6v-19.15Z"
        />
        <path
            d="M215.15,170.93c-9.63,25.94-33.2,45.1-61.61,48.38v18.25c38.51-3.57,70.22-30.58,80.72-66.63h35.61c-11.48,55.25-58.65,97.43-116.33,101.31v17.43c67.3-3.98,122.26-53.83,134.05-118.73,1.03-5.69,1.74-11.5,2.08-17.4h-70.35c-.7,6.05-2.12,11.88-4.17,17.4"
        />
        <path
            d="M86.74,153.53h-16.39c.7,6.05,2.12,11.88,4.17,17.4,2.9,7.8,7.05,14.99,12.22,21.31,8.36,10.23,19.38,18.22,31.99,22.9v54.72c-11.28-2.34-22.02-6.17-31.99-11.28-6.1-3.12-11.92-6.72-17.4-10.74-6.26-4.59-12.08-9.75-17.4-15.38-6.64-7.03-12.48-14.82-17.4-23.22-6.85-11.71-11.89-24.61-14.74-38.31-1.18-5.67-1.98-11.48-2.38-17.4H0c.35,5.9,1.05,11.71,2.08,17.4,2.77,15.25,7.92,29.67,15.06,42.85,4.91,9.07,10.75,17.55,17.4,25.32,5.31,6.2,11.13,11.95,17.4,17.18,5.47,4.57,11.29,8.74,17.4,12.47,5.56,3.4,11.38,6.43,17.4,9.06,10.11,4.42,20.83,7.73,31.99,9.76,5.69,1.03,11.5,1.74,17.4,2.08v-87.35c-6.17-.93-12.02-2.82-17.4-5.53-11.15-5.61-20.24-14.71-25.85-25.85-2.71-5.38-4.6-11.23-5.53-17.4h-.61Z"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'HaloIconWhite' });
</script>
