<template>
    <router-view></router-view>
</template>

<script lang="ts">
import { configureYupLocale } from '@/utils/yupConfig';
import { defineComponent, watch } from 'vue';
import { userState } from '@/composables/useUser';
import { includeTag } from '@/utils/helper';

export default defineComponent({
    name: 'App',
    setup () {
        const { getUser } = userState;

        configureYupLocale();

        const unwatch = watch(
            () => {
                return getUser.value;
            },
            (value) => {
                if (value.prefixedId) {
                    includeTag('script', {
                        type: 'text/javascript',
                        'data-jsd-embedded': '',
                        'data-key': '032ac14b-797f-4de8-95e2-95d49a6a81ea',
                        'data-base-url': 'https://jsd-widget.atlassian.com',
                        src: 'https://jsd-widget.atlassian.com/assets/embed.js'
                    });
                    unwatch();
                }
            }
        );
    }
});
</script>
