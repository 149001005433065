import { QueryExecutionContext } from 'villus';
import { computed, ComputedRef } from 'vue';

export enum Context {
    centralApp,
    tenantApp
}

export interface ContextComposable {
    setContext: (context: Context) => void,
    isCentralApp: ComputedRef<boolean>,
    isTenantApp: ComputedRef<boolean>,
    actAsLandlord: (tenantPrefixedId: string|undefined) => QueryExecutionContext|undefined
}

const contextState = { currentContext: Context.centralApp };

export default function useContext (): ContextComposable {
    return {
        setContext (context: Context) {
            contextState.currentContext = context;
        },
        isCentralApp: computed(() => { return contextState.currentContext === Context.centralApp; }),
        isTenantApp: computed(() => { return contextState.currentContext === Context.tenantApp; }),

        /**
         * Return the headers needed to act as a Landlord in the central app if tenantPrefixedId is provided.
         */
        actAsLandlord (tenantPrefixedId: string|undefined): QueryExecutionContext|undefined {
            return tenantPrefixedId ?
                { headers: { 'X-Landlord-Tenant': tenantPrefixedId } } :
                undefined;
        }
    };
}
