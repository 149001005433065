import { CompleteTutorialStepDocument } from '@/graphql/operations';
import { DESKTOP_SCREEN_WIDTH } from '@/utils/constants';
import { useMutation } from 'villus';
import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators';
import { TourData } from '@/types';

@Module({ name: 'tour', namespaced: true })
export default class Tour extends VuexModule {
    private _currentStep = 0;

    private _key = 0;

    private _tourData: TourData[] = [];

    private _tour: number | null = null;

    private _showGivingTutorialWidget: boolean | null = null;

    showGivingTutorialWidgetSessionKey = 'showGivingTutorialWidget';

    get tourState (): { currentStep: number; tourData: TourData[], key: number, tour: number | null } {
        return {
            currentStep: this._currentStep,
            tourData: this._tourData,
            key: this._key,
            tour: this._tour
        };
    }

    get isInProgress () {
        return !!this._tourData.length;
    }

    get showGivingTutorialWidget (): boolean | null {
        if (!this._showGivingTutorialWidget) {
            const sessionStorageValue = sessionStorage.getItem(this.showGivingTutorialWidgetSessionKey);
            if (sessionStorageValue) {
                try {
                    return JSON.parse(sessionStorageValue);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._showGivingTutorialWidget;
    }

    @Mutation
    private setTourState (tourData: TourData[]): void {
        this._tourData = tourData;
    }

    @Mutation
    private setCurrentStep (step: number): void {
        this._currentStep = step;
    }

    @Mutation
    private setKey (key: number): void {
        this._key = key;
    }

    @Mutation
    private setTour (tour: number | null): void {
        this._tour = tour;
    }

    @Mutation
    private setShowGivingTutorialWidget (show: boolean): void {
        this._showGivingTutorialWidget = show;
        sessionStorage.setItem(this.showGivingTutorialWidgetSessionKey, show.toString());
    }

    @Action
    startTour (tourData: TourData[]): void {
        let step = 0;
        if (window.innerWidth >= DESKTOP_SCREEN_WIDTH) {
            step = tourData.find((item) => {
                return !item.mobileOnly;
            })?.step as number;
        }
        this.setCurrentStep(step);
        this.setTourState(tourData);
    }

    @Action
    doSetShowGivingTutorialWidget (show: boolean): void {
        this.setShowGivingTutorialWidget(show);
    }

    @Action
    storeTourNumber (tour: number | null): void {
        this.setTour(tour);
    }

    @Action
    goToNextStep (): void {
        if (!this.isInProgress) {
            return;
        }

        let nextStep: number;

        if (window.innerWidth >= DESKTOP_SCREEN_WIDTH) {
            nextStep = this._tourData.find((item) => {
                return !item.mobileOnly && item.step > this._currentStep;
            })?.step as number;
        } else {
            nextStep = this._currentStep + 1;
        }

        if (nextStep < this._tourData.length) {
            this.setCurrentStep(nextStep);
            this.increaseKey();

            if (this._tourData.find((stepDetails) => {
                return stepDetails.step === nextStep;
            })?.completeTutorial) {
                this.finishTour(false);
            }
        } else {
            this.exitTour();
        }
    }

    @Action
    goToPreviousStep (): void {
        const previousStep = this._currentStep - 1;
        if (previousStep >= 0) {
            this.setCurrentStep(previousStep);
            this.increaseKey();
        }
    }

    @Action
    exitTour (): void {
        this.setTourState([]);
        this.setCurrentStep(0);
        this.storeTourNumber(null);
        this.increaseKey();
    }

    @Action
    increaseKey (): void {
        if (this.isInProgress) {
            const key = this._key + 1;
            this.setKey(key);
        }
    }

    @Action
    finishTour (exit = true): void {
        if (!this.tourState.tourData.length) {
            return;
        }

        const step = this.tourState.tour;

        if (exit) {
            this.exitTour();
        }

        const { execute: completeTutorialStep } = useMutation(CompleteTutorialStepDocument);

        completeTutorialStep({ step: step as number }).then();
    }
}
