/* eslint no-underscore-dangle: 0 */

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ name: 'donor', namespaced: true })
export default class Donor extends VuexModule {
    // Selected Donors
    _selectedDonors: string[] = [];

    get selectedDonors (): string[] {
        return this._selectedDonors;
    }

    @Mutation
    reset (): void {
        this._selectedDonors = [];
    }

    @Mutation
    selectDonors (selectedDonors: string[]): void {
        this._selectedDonors = selectedDonors;
    }

    @Action({ commit: 'selectDonors' })
    doSelectDonors (selectedDonors: string[]): string[] {
        return selectedDonors;
    }
}
