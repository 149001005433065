import { Module } from '@/types';
import { hubspot } from '@/utils/hubspot';
import { computed, ComputedRef, ref } from 'vue';
import { client, getCsrfToken, kickUserOut } from '@/graphql/client';
import {
    MeDocument,
    Organization, Person,
    Role,
    User,
    ValidTenantDocument
} from '@/graphql/operations';
import { accountStore } from '@/store';
import {
    // getPersonName,
    hasValidActiveOrganization
} from '@/utils/helper';
import {
    BroadcastingConnect,
    BroadcastingListenEvent
} from '@/composables/useBroadcasting';
import { BROADCASTING_CHANNELS, BROADCASTING_EVENTS } from '@/utils/constants';

const state = ref({
    user: {} as User,
    viewingPerson: {} as Person | null | undefined,
    validTenant: null as null | boolean
});

const getUser: ComputedRef<User> = computed(() => {
    return state.value.user;
});
const getViewingPerson: ComputedRef<Person | null | undefined> = computed(() => {
    return state.value.viewingPerson;
});

const check = (): boolean => {
    if (Object.entries(state.value.user).length !== 0) {
        BroadcastingConnect();
        return true;
    }
    return false;
};

const checkBilling = async (): Promise<boolean> => {
    if (!state.value.validTenant) {
        const result = await client.executeQuery({ query: ValidTenantDocument });
        state.value.validTenant = result.data?.validTenant ?? false;
    }

    return state.value.validTenant;
};

// const initializePendo = (visitor: User, account: Organization) => {
//     if (typeof pendo !== 'undefined' && pendo.initialize && import.meta.env.VITE_ENV === 'production') {
//         pendo.initialize({
//             visitor: {
//                 id: visitor.prefixedId,                  // Required if user is logged in, default creates anonymous ID
//                 email: visitor.email,                    // Recommended if using Pendo Feedback, or NPS Email
//                 full_name: getPersonName(visitor.person)
//                 // role:                                 // Optional
//
//                 // You can add any additional visitor level key-values here,
//                 // as long as it's not one of the above reserved names.
//             },
//
//             account: {
//                 id: account.prefixedId,               // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
//                 name: account.name,                   // Optional
//                 adconId: account.adconOrganizationId
//                 // is_paying:                         // Recommended if using Pendo Feedback
//                 // monthly_value:                     // Recommended if using Pendo Feedback
//                 // planLevel:                         // Optional
//                 // planPrice:                         // Optional
//                 // creationDate:                      // Optional
//
//                 // You can add any additional account level key-values here,
//                 // as long as it's not one of the above reserved names.
//             }
//         })
//     }
// }

function setUser (user: User): void {
    state.value.user = user;
}

function setViewingPerson (viewingPerson: Person | null | undefined): void {
    state.value.viewingPerson = viewingPerson;
}

async function fetchUser (): Promise<void> {
    if (!getCsrfToken()) {
        return kickUserOut();
    }

    const result = await client.executeQuery({ query: MeDocument });

    if (![419, 401].includes(result.error?.response?.status) && result.data) {
        setUser(result.data.me as User);

        if (!hasValidActiveOrganization(accountStore.activeOrganization, getUser.value)) {
            if (result.data.me.tenant?.primaryOrganization.availableModules?.length) {
                accountStore.doSetActiveOrganization(result.data.me.tenant?.primaryOrganization as Organization);
            } else {
                accountStore.doSetActiveOrganization(
                        result.data.me.tenant?.secondaryOrganizations?.find(
                            (org) => {
                                return org.availableModules?.length;
                            }
                        ) as Organization
                );
            }
        }

        if (['production'].includes(import.meta.env.VITE_ENV)) {
            // initializePendo(getUser.value as User, accountStore.activeOrganization as Organization);
            hubspot.identifyVisitor(getUser.value.email as string);
        }

        if (result.error?.response.status === 500) {
            accountStore.resetImpersonationReturnUrl();
            accountStore.resetImpersonatedUser();
        }

        BroadcastingListenEvent(
            `${BROADCASTING_CHANNELS.TENANT}.${getUser.value.tenant?.prefixedId}`,
            BROADCASTING_EVENTS.TENANT_ORGANIZATIONS_UPDATED,
            () => {
                return fetchUser();
            }
        );
    }
}

function isHaloAdmin (): boolean {
    const user = getUser.value;

    if (!user) {
        return false;
    }

    return user.isHaloAdmin;
}

function isAdmin (): boolean {
    const user = getUser.value;

    if (!user) {
        return false;
    }

    return !!user.roles?.some((role: Role) => {
        return role.isTenantRole;
    });
}

function hasModuleForCurrentOrg (moduleName: string) {
    return !!accountStore.activeOrganization?.availableModules?.find(
        (module: Module) => {
            return module.name.includes(moduleName) && (module._isActive === undefined || module._isActive);
        }
    );
}

function isOnAdminTeam (): boolean {
    const user = getUser.value;

    return !!user.rolesTeams?.find((team) => {
        return team.id === user.tenant?.adminTeamId;
    });
}

const userState = {
    getUser,
    getViewingUser: getViewingPerson,
    check,
    isHaloAdmin,
    checkBilling,
    isAdmin,
    isOnAdminTeam
};

export { userState, fetchUser, setViewingPerson, hasModuleForCurrentOrg };
