/* eslint no-underscore-dangle: 0 */

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { UserFilters } from '@/types';

@Module({ name: 'user', namespaced: true })
export default class User extends VuexModule {
    // User Filters
    _userFilters: UserFilters = {
        type: 'users',
        organizationPrefixedId: ''
    };

    get userFilters (): UserFilters {
        return this._userFilters;
    }

    @Mutation
    reset (): void {
        this._userFilters = {
            type: 'users',
            organizationPrefixedId: ''
        };
    }

    @Mutation
    setUserFilters (userFilters: UserFilters): void {
        this._userFilters = userFilters;
    }

    @Action({ commit: 'setUserFilters' })
    doSetUserFilters (userFilters: UserFilters): UserFilters {
        return userFilters;
    }
}
