import { Component } from 'vue';
import { RouteRecordRaw } from 'vue-router';

const authRoutes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: { name: 'login' },
        component: (): Component => {
            return import('../layouts/AuthLayout.vue');
        },
        children: [
            {
                path: '/login',
                name: 'login',
                component: (): Component => {
                    return import('../views/auth/Login.vue');
                },
                meta: { guest: true }
            },
            {
                path: ':lang?/register',
                name: 'register',
                component: (): Component => {
                    return import('../views/auth/Register.vue');
                },
                meta: { guest: true },
                props: (route) => {
                    return { codes: route.query, lang: route.params.lang };
                }
            },
            {
                path: '/forgot-password',
                name: 'forgotPassword',
                component: (): Component => {
                    return import('../views/auth/ForgotPassword.vue');
                },
                meta: { guest: true }
            },
            {
                path: '/reset-password/:token',
                name: 'resetPassword',
                component: (): Component => {
                    return import('../views/auth/ResetPassword.vue');
                },
                props: true,
                meta: { guest: true }
            },
            {
                path: '/invite/:token',
                name: 'invite',
                component: (): Component => {
                    return import('../views/auth/Invite.vue');
                },
                props: true,
                meta: { guest: true }
            },
            {
                path: '/join/:tenantPrefixedId/:userPrefixedId',
                name: 'join',
                component: (): Component => {
                    return import('../views/auth/Join.vue');
                },
                props: true,
                meta: { guest: true }
            },
            {
                path: 'referral/:aff?/:promo?',
                name: 'referral',
                redirect: ({ params }) => {
                    const query = Object.fromEntries(
                        Object.entries(params).filter(([, value]) => {
                            return value ?? false;
                        })
                    );

                    return { name: 'register', query: Object.keys(query).length ? query : undefined };
                },
                meta: { guest: true }
            }
        ]
    }
];

export default authRoutes;
