/* eslint no-underscore-dangle: 0 */

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { FlashItems } from '@/types';

@Module({ name: 'flash', namespaced: true })
export default class Flash extends VuexModule {
    localStorageKeys = {
        loginNotice: 'loginNotice',
        billingNotice: 'billingNotice'
    };

    _isOpen = false;

    get isOpen (): boolean {
        return this._isOpen;
    }

    _message = '';

    get message (): string {
        return this._message;
    }

    _level = 'success';

    get level (): string {
        return this._level;
    }

    _loginNotice = '';

    get loginNotice (): string {
        if (!this._loginNotice) {
            const localStorageValue = localStorage.getItem(this.localStorageKeys.loginNotice);
            if (localStorageValue) {
                try {
                    return JSON.parse(localStorageValue);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._loginNotice;
    }

    _billingNotice = '';

    get billingNotice (): string {
        if (!this._billingNotice) {
            const localStorageValue = localStorage.getItem(this.localStorageKeys.billingNotice);
            if (localStorageValue) {
                try {
                    return JSON.parse(localStorageValue);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._billingNotice;
    }

    @Mutation
    setMessage (message: string): void {
        this._message = message;
    }

    @Action({ commit: 'setMessage' })
    doSetMessage (message: string): string {
        return message;
    }

    @Mutation
    setLevel (level: string): void {
        this._level = level;
    }

    @Action({ commit: 'setLevel' })
    doSetLevel (level: string): string {
        return level;
    }

    @Mutation
    setIsOpen (isOpen: boolean): void {
        this._isOpen = isOpen;
    }

    @Action({ commit: 'setIsOpen' })
    doSetIsOpen (isOpen: boolean): boolean {
        return isOpen;
    }

    @Action({})
    doFlash (items: FlashItems): void {
        this.context.commit('setMessage', items.message);
        this.context.commit('setLevel', items.level ?? 'success');
        this.context.commit('setIsOpen', true);
    }

    @Action({})
    doClose (): void {
        this.context.commit('setIsOpen', false);
    }

    @Mutation
    setLoginNotice (loginNotice: string): void {
        this._loginNotice = loginNotice;
    }

    @Action({ commit: 'setLoginNotice' })
    doSetLoginNotice (loginNotice: string): string {
        if (loginNotice) {
            localStorage.setItem(this.localStorageKeys.loginNotice, JSON.stringify(loginNotice));
        } else {
            localStorage.removeItem(this.localStorageKeys.loginNotice);
        }
        return loginNotice;
    }

    @Mutation
    setBillingNotice (billingNotice: string): void {
        this._billingNotice = billingNotice;
    }

    @Action({ commit: 'setBillingNotice' })
    doSetBillingNotice (billingNotice: string): string {
        if (billingNotice) {
            localStorage.setItem(this.localStorageKeys.billingNotice, JSON.stringify(billingNotice));
        } else {
            localStorage.removeItem(this.localStorageKeys.billingNotice);
        }
        return billingNotice;
    }
}
