import { LaunchProject } from '@/graphql/operations';
import { graphqlService } from '@/utils/launch';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { LaunchTask, LaunchTopic, NavigationLink } from '@/types';

@Module({ name: 'launch', namespaced: true })
export default class Launch extends VuexModule {
    localStorageKeys = {
        activeTopic: 'activeTopic',
        returnRoute: 'returnRoute'
    };

    _activeTopic: LaunchTopic | null = null;

    _returnRoute: NavigationLink | null = null;

    _launchProject: LaunchProject | null = null;

    _launchProjectRefresh = 0;

    get activeTopic (): LaunchTopic | null {
        if (!this._activeTopic) {
            const localStorageValue = localStorage.getItem(this.localStorageKeys.activeTopic);
            if (localStorageValue) {
                try {
                    return JSON.parse(localStorageValue);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._activeTopic;
    }

    get returnRoute (): NavigationLink | null {
        if (!this._returnRoute) {
            const localStorageValue = localStorage.getItem(this.localStorageKeys.returnRoute);
            if (localStorageValue) {
                try {
                    return JSON.parse(localStorageValue);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return this._returnRoute;
    }

    get launchProject (): LaunchProject | null {
        return this._launchProject;
    }

    get launchProjectRefresh (): number {
        return this._launchProjectRefresh;
    }

    @Mutation
    setActiveTopic (activeTopic: LaunchTopic): void {
        this._activeTopic = activeTopic;
    }

    @Action({ commit: 'setActiveTopic' })
    doSetActiveTopic (activeTopic: LaunchTopic): LaunchTopic {
        localStorage.setItem(this.localStorageKeys.activeTopic, JSON.stringify(activeTopic));

        return activeTopic;
    }

    @Mutation
    setReturnRoute (returnRoute: NavigationLink): void {
        this._returnRoute = returnRoute;
    }

    @Action({ commit: 'setReturnRoute' })
    doSetReturnRoute (returnRoute: NavigationLink): NavigationLink {
        localStorage.setItem(this.localStorageKeys.returnRoute, JSON.stringify(returnRoute));

        return returnRoute;
    }

    @Mutation
    setLaunchProject (launchProject: LaunchProject): void {
        this._launchProject = launchProject;
    }

    @Action
    doSetLaunchProject (launchProject: LaunchProject): void {
        this.setLaunchProject(launchProject);
    }

    @Action
    async doCheckTask (launchTask: LaunchTask) {
        if (!this._launchProject) return;

        const updatedLaunchProject = {
            ...this._launchProject,
            launchPhases: this._launchProject?.launchPhases.map((phase) => {
                return {
                    ...phase,
                    launchTopics: phase.launchTopics.map((topic) => {
                        return {
                            ...topic,
                            launchTasks: topic.launchTasks.map((task) => {
                                return {
                                    ...task,
                                    isChecked: task.prefixedId === launchTask.prefixedId ? !launchTask.isChecked : task.isChecked,
                                    _modified: true
                                };
                            })
                        };
                    })
                };
            })
        } as LaunchProject;

        this.setLaunchProject(updatedLaunchProject);

        if (launchTask.isChecked) {
            await graphqlService.uncheckLaunchTasks({
                prefixedIds: launchTask.prefixedId,
                projectPrefixedId: this._launchProject.prefixedId
            });
        } else {
            await graphqlService.checkLaunchTasks({
                prefixedIds: launchTask.prefixedId,
                projectPrefixedId: this._launchProject.prefixedId
            });
        }
    }

    @Mutation
    resetLaunchProject (): void {
        this._launchProject = null;
    }

    @Mutation
    setLaunchProjectRefresh (launchProjectRefresh: number): void {
        this._launchProjectRefresh = launchProjectRefresh;
    }

    @Action({ commit: 'setLaunchProjectRefresh' })
    doSetLaunchProjectRefresh (launchProjectRefresh: number): number {
        return launchProjectRefresh;
    }
}
