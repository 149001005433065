import { createLogger, Store } from 'vuex';
import { initialiseStores, modules } from '@/store/utils/store-acessor';

const debug = false;

const initializer = (store: Store<unknown>): void => { return initialiseStores(store); };
export const plugins = [initializer, ...(debug ? [createLogger()] : [])];
export * from '@/store/utils/store-acessor';

export default new Store({
    plugins,
    modules,
    state: {},
    mutations: {},
    actions: {},
    strict: debug
});
