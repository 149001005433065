import { Component } from 'vue';
import { RouteRecordRaw } from 'vue-router';

const myProfileRoutes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: 'my-profile.dashboard',
        redirect: { name: 'my-profile' }
    },
    {
        path: '/my-profile',
        name: 'my-profile',
        redirect: { name: 'my-profile.profile.index' },
        components: {
            default: () => {
                return import('../views/Profile.vue');
            },
            billingNotice: (): Component => {
                return import('../components/alerts/BillingNotice.vue');
            }
        },
        meta: { auth: true },
        children: [
            {
                path: '',
                name: 'my-profile.profile.index',
                component: (): Component => {
                    return import('../views/profile/ProfileView.vue');
                },
                props: true
            },
            {
                path: ':userPrefixedId?/edit',
                name: 'my-profile.profile.edit',
                component: (): Component => {
                    return import('../views/profile/ProfileEdit.vue');
                },
                props: true
            },
            {
                path: ':userPrefixedId?/update-contact',
                name: 'my-profile.profile.updateContact',
                component: (): Component => {
                    return import('../views/profile/ContactUpdate.vue');
                },
                props: true
            },
            {
                path: 'add',
                name: 'my-profile.profile.add',
                component: (): Component => {
                    return import('../views/profile/ContactAdd.vue');
                }
            },
            {
                path: 'launch',
                name: 'my-profile.profile.launch',
                component: (): Component => {
                    return import('../views/profile/ProfileLaunch.vue');
                },
                props: true
            },
            {
                path: 'contact',
                name: 'my-profile.profile.contact',
                component: (): Component => {
                    return import('../views/profile/ContactView.vue');
                },
                props: true
            },
            {
                path: 'giving',
                name: 'my-profile.profile.giving',
                component: (): Component => {
                    return import('../views/profile/ProfileGiving.vue');
                },
                props: true
            },
            {
                path: ':personPrefixedId?/families',
                name: 'my-profile.profile.families',
                component: (): Component => {
                    return import('../views/profile/ProfileFamilies.vue');
                },
                props: true
            },
            {
                path: 'admin',
                name: 'my-profile.profile.permissions',
                component: (): Component => {
                    return import('../views/profile/ProfilePermissions.vue');
                },
                props: true
            },
            {
                path: 'billing',
                name: 'my-profile.profile.billing',
                component: (): Component => {
                    return import('../views/profile/ProfileView.vue');
                },
                props: true
            },
            {
                path: 'notifications',
                name: 'my-profile.profile.notifications',
                component: (): Component => {
                    return import('../views/profile/ProfileNotifications.vue');
                },
                props: true
            }
        ]
    }
];

export default myProfileRoutes;
