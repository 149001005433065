import { RouteRecordRaw } from 'vue-router';

const guestRoutes: Array<RouteRecordRaw> = [
    {
        path: '/give/:slug/:previewCode?',
        name: 'give',
        component: () => {
            return import('../views/onlineGiving/Give.vue');
        },
        meta: { guest: true },
        props: true
    },
    {
        path: '/give-not-found',
        name: 'give-not-found',
        component: () => {
            return import('../views/onlineGiving/NotFoundGiving.vue');
        },
        meta: { guest: true },
        props: true
    }
];

export default guestRoutes;
