function trackPageView (route: string) {
    if ((window as any)._hsq) {
        const _hsq = (window as any)._hsq;
        _hsq.push(['setPath', route]);
        _hsq.push(['trackPageView']);
    }
}

function identifyVisitor (email: string) {
    if ((window as any)._hsq) {
        const _hsq = (window as any)._hsq;

        _hsq.push(['identify'], { email: email });
    }
}

const hubspot = {
    trackPageView,
    identifyVisitor
};

export { hubspot };
