import { createClient, defaultPlugins, definePlugin } from "villus";
import NProgress from "nprogress";
import useCookie, { eraseCookie } from "@/composables/useCookie";
import { captureException } from "@sentry/browser";
import { logout } from "@/composables/useAuth";
import { getApiUrl } from "@/utils/api";
import { accountStore, flashStore } from "@/store";

export const getCsrfToken = (): string | null => useCookie("XSRF-TOKEN");

export const resetCsrfToken = (): void => eraseCookie("XSRF-TOKEN");

const csrfPlugin = definePlugin(({ opContext }) => {
    const csrfToken = getCsrfToken();

    if (csrfToken === null) {
        return;
    }

    /* eslint-disable */
    opContext.headers["X-XSRF-TOKEN"] = csrfToken;
    opContext.credentials = "include";
});

const organizationContext = definePlugin(({ opContext }) => {
    opContext.headers["X-Landlord-Organization"] = accountStore.activeOrganization?.prefixedId ?? "";
});

const userImpersonation = definePlugin(({opContext}) => {
    opContext.headers['X-Impersonated-User'] = accountStore.impersonatedUser?.prefixedId ?? '';
})

const localization = definePlugin(({opContext}) => {
    opContext.headers['X-Locale'] = localStorage.getItem('user-locale') ?? '';
})

const progressPlugin = definePlugin(({ afterQuery }) => {
    NProgress.start();

    afterQuery(() => {
        NProgress.done();
    });
});

export const kickUserOut = (): void => {
    return window.location.replace(
      "/login?p=" + window.location.pathname
    );
}

/**
 * Reports unknown errors to sentry to avoid having to do that everywhere.
 */
const sentryReportPlugin = definePlugin(({ operation, afterQuery }) => {
    afterQuery(async ({ error }, { response }) => {
        if (
            response?.status === 419 &&
            ![
                "/login",
                "/",
                "",
                "/register",
                "/forgot-password",
                "/reset-password",
                "/invite",
                "/sign-up-billing",
                "/join",
            ].includes(window.location.pathname)
        ) {
            return kickUserOut();
        }

        try {
            const data = response?.body as any;
            if (data && data.inactiveAccount) {
                if (data.billingPortalUrl) {
                    flashStore.doSetLoginNotice(`Your account is not active, please visit the link below or contact your administrator.<br/>
<u><a href="${data.billingPortalUrl}" target="_blank">Billing Portal</a></u>`);
                } else {
                    flashStore.doSetLoginNotice(
                        "Your account is not active, please contact your administrator"
                    );
                }

                await logout();
                return;
            }
        } catch (e) {}

        try {
            if (response?.body?.errors && response?.body?.errors[0]?.extensions?.category === 401) {
                flashStore.doSetLoginNotice("Please Log In to view this page");
                await logout();
                return;
            }
        } catch (e) {}

        // collect some information about the query that failed
        const operationContext = {
            query: operation.query,
            type: operation.type,
            variables: operation.variables,
        };

        if ((response?.status || 200) >= 500) {
            captureException(error, {
                contexts: {
                    info: {
                        description: "received 500 response code from API",
                    },
                    operation: operationContext,
                },
            });
        }

        // Other kinds of error codes should be handled by the consuming component
        if (error) {
            captureException(error, {
                contexts: {
                    operation: operationContext,
                },
            });
        }
    });
});

export const client = createClient({
    url: getApiUrl(),
    use: [
        csrfPlugin,
        progressPlugin,
        sentryReportPlugin,
        organizationContext,
        userImpersonation,
        localization,
        ...defaultPlugins(),
    ],
    cachePolicy: "network-only",
});
