import Pusher from 'pusher-js';
import Echo, { Channel } from 'laravel-echo';
import { getCsrfToken } from '@/graphql/client';
import { accountStore } from '@/store';
import { ref } from 'vue';

const state = ref({
    queueListeningEvents: [] as unknown as {
        channel: string;
        event: string;
        callback: (event: any) => void;
        replaceExisting: boolean;
    }[],
    listeningEvents: [] as unknown as { event: string; channel: Channel }[],
    connected: false
});

const BroadcastingStopListeningEvent = (
    channel: string,
    event: string
): Channel => {
    return (window as any).Echo.private(channel).stopListening(event);
};

const BroadcastingListenEvent = (
    channel: string,
    event: string,
    callback: (event: any) => void,
    replaceExisting = true
): Channel | null => {
    if (!state.value.connected) {
        state.value.queueListeningEvents.push({
            channel,
            event,
            callback,
            replaceExisting
        });
        return null;
    }

    if (replaceExisting) {
        BroadcastingStopListeningEvent(channel, event);
    } else {
        const existingChannel = state.value.listeningEvents.find((e) => {
            return e.event === event;
        });
        if (existingChannel) {
            return existingChannel.channel;
        }
    }

    const resultChannel = (window as any).Echo.private(channel).listen(
        event,
        callback
    );

    state.value.listeningEvents.push({ event, channel: resultChannel });

    return resultChannel;
};

const BroadcastingConnect = (debugMode = false): boolean => {
    if (state.value.connected) {
        if (debugMode) {
            console.debug('EchoConnect - Already connected.');
        }
        return false;
    }

    if (
        !import.meta.env.VITE_PUSHER_APP_KEY ||
        !import.meta.env.VITE_PUSHER_APP_CLUSTER
    ) {
        if (debugMode) {
            console.debug('EchoConnect - No env variables found.');
        }
        return false;
    }

    const csrfToken = getCsrfToken();

    if (csrfToken === null) {
        if (debugMode) {
            console.debug('EchoConnect - No CSRF token found.');
        }
        return false;
    }

    if (!accountStore.activeOrganization?.prefixedId) {
        return false;
    }

    (Pusher as any).Runtime.createXHR = function () {
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        return xhr;
    };

    Pusher.logToConsole = import.meta.env.VITE_ENV === 'local';

    (window as any).Pusher = Pusher;

    (window as any).Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: true,
        authEndpoint: `${import.meta.env.VITE_API_BASE_URL}/broadcasting/auth`,
        encrypted: true,
        auth: {
            withCredentials: true,
            headers: { 'X-XSRF-TOKEN': csrfToken }
        }
    });

    state.value.connected = true;

    if (state.value.queueListeningEvents) {
        for (const queueEvent of state.value.queueListeningEvents) {
            BroadcastingListenEvent(
                queueEvent.channel,
                queueEvent.event,
                queueEvent.callback,
                queueEvent.replaceExisting
            );
        }

        state.value.queueListeningEvents = [];
    }

    return true;
};

export {
    BroadcastingConnect,
    BroadcastingListenEvent,
    BroadcastingStopListeningEvent
};
