import { accountStore, flashStore } from '@/store';
import { getApiBaseUrl } from '@/utils/api';
import useCookie from './useCookie';
import { resetCsrfToken } from '@/graphql/client';
import i18n from '../i18n';

const { t } = i18n.global;

export function fetchHttp (uri: string, options = {}): Promise<Response> {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');

    const csrf = useCookie('XSRF-TOKEN');

    if (csrf) {
        headers.set('X-XSRF-TOKEN', csrf);
    }

    Object.assign(options, { credentials: 'include', headers });

    return fetch(`${getApiBaseUrl()}${uri}`, options);
}

export const fetchCsrf = async () => {
    const csrfResponse = await fetch(`${getApiBaseUrl()}/sanctum/csrf-cookie`, { credentials: 'include' });

    if (!csrfResponse.ok) {
        flashStore.doFlash({ message: t('flash.serverProblem') });
    }
};

export const login = async (
    payload: Record<string, unknown>
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/login', {
        method: 'post',
        body: JSON.stringify(payload)
    });
};

export const register = async (
    payload: Record<string, unknown>
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/register', {
        method: 'post',
        body: JSON.stringify(payload)
    });
};

export const validateRegister = async (
    payload: Record<string, unknown>
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/api/validate-register', {
        method: 'post',
        body: JSON.stringify(payload)
    });
};

export const logout = async (): Promise<Response> => {
    const result = await fetchHttp('/logout', { method: 'post' });

    resetCsrfToken();
    accountStore.resetImpersonatedUser();
    accountStore.resetImpersonationReturnUrl();
    accountStore.doSetActiveOrganization(null);

    return result;
};

export const forgotPassword = async (
    payload: Record<string, unknown>
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/forgot-password', {
        method: 'post',
        body: JSON.stringify(payload)
    });
};

export const resetPassword = async (
    payload: Record<string, unknown>
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/reset-password', {
        method: 'post',
        body: JSON.stringify(payload)
    });
};

export const getInvite = async (token: string): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp(`/api/invite/${token}`, { method: 'get' });
};

export const getJoin = async (
    tenantPrefixedId: string,
    userPrefixedId: string
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp(`/api/join/${tenantPrefixedId}/${userPrefixedId}`, { method: 'get' });
};

export const acceptInvite = async (
    payload: Record<string, unknown>
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/api/invite', {
        method: 'post',
        body: JSON.stringify(payload)
    });
};

export const acceptJoin = async (
    payload: Record<string, unknown>
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/api/join', {
        method: 'post',
        body: JSON.stringify(payload)
    });
};

export const createSetupIntent = async (): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/api/setup-intent', { method: 'post' });
};

export const getProducts = async (): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/api/products', { method: 'get' });
};

export const getOnlineGiving = async (slug: string): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp(`/api/online-giving/${slug}`, { method: 'get' });
};

export const onlineGivingPayment = async (
    payload: Record<string, unknown>
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/api/give', {
        method: 'post',
        body: JSON.stringify(payload)
    });
};

export const getPromoCode = async (
    payload: Record<string, unknown>
): Promise<Response> => {
    await fetchCsrf();

    return fetchHttp('/api/promo-code', {
        method: 'post',
        body: JSON.stringify(payload)
    });
};
