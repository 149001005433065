import { Component } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import i18n from '../i18n';

const { t } = i18n.global;

const dashboardRoutes: Array<RouteRecordRaw> = [
    {
        path: '',
        components: {
            navigationLinks: (): Component => {
                return import('../components/navigation/DashboardLinks.vue');
            },
            default: () => {
                return import('../views/haloDashboard/Halo.vue');
            },
            billingNotice: (): Component => {
                return import('../components/alerts/BillingNotice.vue');
            }
        },
        meta: { title: t('navigation.haloDashboard') },
        children: [
            {
                path: '',
                name: 'dashboard',
                redirect: { name: 'giving.dashboard' },
                component: (): Component => {
                    return import('../views/haloDashboard/HaloDashboard.vue');
                },
                props: true,
                children: []
            },
            {
                path: 'people',
                component: (): Component => {
                    return import('../views/People.vue');
                },
                children: [
                    {
                        path: '',
                        name: 'dashboard.people.index',
                        component: (): Component => {
                            return import('../views/haloDashboard/people/PeopleIndex.vue');
                        },
                        meta: { description: 'All People' },
                        props: true
                    }
                ]
            },
            {
                path: 'people/profile',
                name: 'dashboard.profile',
                redirect: { name: 'dashboard.profile.contact' },
                component: (): Component => {
                    return import('../views/Profile.vue');
                },
                meta: { auth: true },
                props: true,
                children: [
                    {
                        path: ':personPrefixedId?',
                        name: 'dashboard.profile.index',
                        component: (): Component => {
                            return import('../views/profile/ProfileView.vue');
                        },
                        // eslint-disable-next-line
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/edit',
                        name: 'dashboard.profile.edit',
                        component: (): Component => {
                            return import('../views/profile/ProfileEdit.vue');
                        },
                        // eslint-disable-next-line
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/update-contact',
                        name: 'dashboard.profile.updateContact',
                        component: (): Component => {
                            return import('../views/profile/ContactUpdate.vue');
                        },
                        // eslint-disable-next-line
                        props: true
                    },
                    {
                        path: 'add',
                        name: 'dashboard.profile.add',
                        component: (): Component => {
                            return import('../views/profile/ContactAdd.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/contact',
                        name: 'dashboard.profile.contact',
                        component: (): Component => {
                            return import('../views/profile/ContactView.vue');
                        },
                        // eslint-disable-next-line
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/giving',
                        name: 'dashboard.profile.giving',
                        component: (): Component => {
                            return import('../views/profile/ProfileGiving.vue');
                        },
                        // eslint-disable-next-line
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/launch',
                        name: 'dashboard.profile.launch',
                        component: (): Component => {
                            return import('../views/profile/ProfileLaunch.vue');
                        },
                        // eslint-disable-next-line
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/families',
                        name: 'dashboard.profile.families',
                        component: (): Component => {
                            return import('../views/profile/ProfileFamilies.vue');
                        },
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/admin',
                        name: 'dashboard.profile.permissions',
                        component: (): Component => {
                            return import('../views/profile/ProfilePermissions.vue');
                        },
                        // eslint-disable-next-line
                        props: true
                    },
                    {
                        path: ':personPrefixedId?/admin',
                        name: 'dashboard.profile.notifications',
                        component: (): Component => {
                            return import('../views/profile/ProfileNotifications.vue');
                        },
                        // eslint-disable-next-line
                        props: true
                    }
                ]
            }
        ]
    }

];

export default dashboardRoutes;
